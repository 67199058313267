import React, { useEffect, useState } from "react";
import CustomModal from "../../../components/modals/CustomModal";
import { useSelector } from "react-redux";
import CustomeTable from "../../../components/widget/CustomeTable";

const ViewMinibar = ({ close }) => {
  const { getAnMinibarItem } = useSelector((state) => state.getAnMinibarItem);
  const [datas, setDatas] = useState([]);
  useEffect(() => {
    if (getAnMinibarItem?.items && Array.isArray(getAnMinibarItem?.items)) {
      const data = getAnMinibarItem?.items.map((listItem) => {
        return {
          minibarListId: listItem?._id,
          quantity: listItem?.quantity?.netQuantity,
          measurementUnit: listItem?.quantity?.measurementUnit,
        };
      });
      if (data?.length) {
        setDatas(data);
      }
    }
  }, [getAnMinibarItem]);
  const columnDefs = [
    // {
    //     headerName: "Item Name",
    //     field: "itemName",
    //     filter: true,
    //   },
    {
      headerName: "Quantity",
      field: "quantity",
      filter: true,
    },
    {
      headerName: "Measurement",
      field: "measurementUnit",
      filter: true,
    },
  ];
  console.log("get View DAta", getAnMinibarItem);
  return (
    <CustomModal close={close} heading={"Minibar Items List"}>
      <div className="w-[25vw] ">
        <div className="text-[18px] leading-5 text-[#6E7C87] font-semibold capitalize mt-4 mb-1">
          {getAnMinibarItem?.minibar}
        </div>
        <CustomeTable
          size="h-[50vh]"
          columnDefs={columnDefs}
          rowData={datas}
          pagination={false}
          showButtons={false}
        />
      </div>
    </CustomModal>
  );
};

export default ViewMinibar;
