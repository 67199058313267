import {
  getHotelAssetByIdSlice,
  hotelAssetSlice,
} from "./slices/asset/hotelAssetSlice";
import { authTokenSlice, ownerAuthSlice } from "./slices/auth/authSlice";
import { getOwnerProfileSlice } from "./slices/auth/profileSlice";
import {
  addBookingSlice,
  deleteBookingSlice,
  updateBookingSlice,
} from "./slices/booking/bookingSlice";
import { getBookingByCondionsSlice } from "./slices/booking/getBookingByConditionsSlice";
import { addEmployeeLeaveSlice } from "./slices/employee/employeeLeaveSlice";
import {
  deleteEmployeePermissionSlice,
  employeePermissionSlice,
} from "./slices/employee/employeePermissionSlice";
import { updateHotelEmployeeSlice } from "./slices/employee/employeeProfileSlice";
import {
  approveEmployeeReviewSlice,
  deleteEmployeeReviewByHotelIdSlice,
  getAllEmployeeReviewSlice,
  replyEmployeeReviewByHotelIdSlice,
} from "./slices/employee/employeeReviewSlice";
import {
  employeeEndShiftSlice,
  employeeStartShiftSlice,
  getEmployeeAllShiftSlice,
} from "./slices/employee/employeeShiftSlice";
import {
  addEmployeeSlice,
  getAllHotelEmployeeSlice,
  getHotelEmployeeByIdSlice,
} from "./slices/employee/employeeSlice";
import {
  addEmployeeExtraWorkSlice,
  deleteEmployeeExtraWorkSlice,
} from "./slices/employee/employeeWorkSlice";
import {
  addExpenseSlice,
  getAllExpenseSlice,
} from "./slices/expense/expenseSlice";
import { getFoodMenuSlice } from "./slices/food/foodMenuSlice";
import { foodOrderSlice } from "./slices/food/foodOrderSlice";
import { getHotelGuestByCodeSlice } from "./slices/guest/getHotelGuestSlice";
import {
  guestCheckInCodeGeneratedSlice,
  guestCheckOutCodeSlice,
} from "./slices/guest/guestCheckInAndOutCodeSlice";
import { getAllGuestQueriesSlice } from "./slices/guest/guestSupportSlice";
import {
  getAllHotelsSlice,
  getHotelByIdSlice,
} from "./slices/hotel/hotelSlice";
import { hotelServiceSlice } from "./slices/hotel/serviceSlice";
import {
  changeStatusOfOrderSlice,
  getEmployeeOrderInQueueSlice,
  getInventorySlice,
  lostInventoryReportSlice,
} from "./slices/inventory/inventoryEmployeeSlice";
import {
  addOwnerKitchenInventorySlice,
  getOwnerKitchenInventorySlice,
  updateOwnerKitchenInventorySlice,
} from "./slices/inventory/inventoryOwnerSlice";
import {
  getKitchenInventoryStockSlice,
  updateKitchenInventoryStockSlice,
} from "./slices/inventory/inventoryStockSlice";
import {
  addKitchenSlice,
  deletekitchenSlice,
  getAllKitchenSlice,
  getKitchenSlice,
  updateKitchenSlice,
} from "./slices/kitchen/kitchenSlice";
import {
  addItemToKitchenMenuSlice,
  deletekitchenMenuItemSlice,
  getAllKitchenMenuItemSlice,
  updateKitchenMenuItemSlice,
} from "./slices/kitchen/menuSlice";
import { getAllBookingsSalesReportSlice } from "./slices/reports/allBookingsSalesReportSlice";
import {
  assetsReportAllTimeForOneAssetSlice,
  assetsReportAllTimeSlice,
} from "./slices/reports/assetReportAllTimeSlice";
import { assetsReportByDateSlice } from "./slices/reports/assetsReportByDateSlice";
import { assetsReportByMonthSlice } from "./slices/reports/assetsReportByMonthSlice";
import { assetsReportByYearSlice } from "./slices/reports/assetsReportByYearSlice";
import { bookingsSalesReportByYearSlice } from "./slices/reports/bookingSalesReportByYearSlice";
import { bookingsOccupencyReportByDateSlice } from "./slices/reports/bookingsOccupencyReportByDateSlice";
import { bookingsOccupencyReportByMonthSlice } from "./slices/reports/bookingsOccupencyReportByMonthSlice";
import { bookingsOccupencyReportByYearSlice } from "./slices/reports/bookingsOccupencyReportByYearSlice";
import { getAllBookingsOccupencyReportSlice } from "./slices/reports/bookingsOccupencyReportSlice";
import { bookingsSalesReportByDateSlice } from "./slices/reports/bookingsSalesReportByDateSlice";
import { bookingsSalesReportByMonthSlice } from "./slices/reports/bookingsSalesReportByMonthSlice";
import { hamburgerSlice } from "./slices/toggleEvent";
import {
  getTransactionsHistorySlice,
  getWalletSlice,
  updateWalletSlice,
} from "./slices/transection/walletSlice";
import {
  deleteVendorProfileSlice,
  updateVendorProfileSlice,
} from "./slices/vendor/vendorProfileSlice";
import {
  addVendorSlice,
  getAllVendorSlice,
  getVendorByIdSlice,
} from "./slices/vendor/vendorSlice";
import { hotelWifiSlice } from "./slices/wifi/wifiSlice";
import { roomServiceSlice } from "./slices/room/roomService";
import { roomAmenitySlice } from "./slices/room/roomAmenitiesSlice";
import { addRoomSlice } from "./slices/room/roomSlice";
import { roomMinibarItemSlice } from "./slices/room/roomMinibarItemSlice";
import { roomMinibarAnItemSlice, roomMinibarSlice } from "./slices/room/roomMinibarSlice";
// import { addRoomSlice } from "./slices/room/roomSlice";

export const slices = {
  auth: authTokenSlice.reducer, // combined for owner & employees
  user: ownerAuthSlice.reducer, // combined for owner & employees
  ownerProfile: getOwnerProfileSlice.reducer,
  updateHotelEmployee: updateHotelEmployeeSlice.reducer,
  deleteHotelEmployee: updateHotelEmployeeSlice.reducer,
  addEmployee: addEmployeeSlice.reducer,
  hotelEmployees: getAllHotelEmployeeSlice.reducer,
  hotelEmployee: getHotelEmployeeByIdSlice.reducer,
  addEmployeeLeave: addEmployeeLeaveSlice.reducer,
  employeeStartShift: employeeStartShiftSlice.reducer,
  employeeEndShift: employeeEndShiftSlice.reducer,
  employeeGetAllShift: getEmployeeAllShiftSlice.reducer,
  addEmployeeExtraWork: addEmployeeExtraWorkSlice.reducer,
  deleteEmployeeExtraWork: deleteEmployeeExtraWorkSlice.reducer,
  employeePermission: employeePermissionSlice.reducer,
  deleteEmployeePermission: deleteEmployeePermissionSlice.reducer,

  // employee review
  getAllEmployeeReview: getAllEmployeeReviewSlice.reducer,
  approveEmployeeReview: approveEmployeeReviewSlice.reducer,
  replyEmployeeReview: replyEmployeeReviewByHotelIdSlice.reducer,
  deleteEmployeeReview: deleteEmployeeReviewByHotelIdSlice.reducer,
  // booking
  addBooking: addBookingSlice.reducer,
  updateBooking: updateBookingSlice.reducer,
  deleteBooking: deleteBookingSlice.reducer,
  getBookingByConditions: getBookingByCondionsSlice.reducer,
  // hotel
  hotel: getHotelByIdSlice.reducer,
  allHotels: getAllHotelsSlice.reducer,
  // hotel amenity
  // addHotelAmenity: addHotelAmenitySlice.reducer,
  // deleteHotelAmenity: deleteHotelAmenitySlice.reducer,
  // updateHotelAmenityContacts: updateHotelAmenityContactsSlice.reducer,
  // guest
  getHotelGuest: getHotelGuestByCodeSlice.reducer,
  getAllGuestQueries: getAllGuestQueriesSlice.reducer,
  guestCheckInCodeGenerated: guestCheckInCodeGeneratedSlice.reducer,
  guestCheckOutCode: guestCheckOutCodeSlice.reducer,
  //room service
  roomService: roomServiceSlice.reducer,
  // room minibar
  minibar: roomMinibarSlice.reducer,
  getAnMinibarItem:roomMinibarAnItemSlice.reducer,
  //room minibar item
  minibarItem: roomMinibarItemSlice.reducer,
  //room amenity
  roomAmenity: roomAmenitySlice.reducer,
  // room
  hotelRooms: addRoomSlice.reducer,
  // asset - hotel asset
  getHotelAsset: getHotelAssetByIdSlice.reducer,
  hotelAssets: hotelAssetSlice.reducer,
  // vendor
  addVendor: addVendorSlice.reducer,
  getVendor: getVendorByIdSlice.reducer,
  getAllVendor: getAllVendorSlice.reducer,
  updateVendorProfile: updateVendorProfileSlice.reducer,
  deleteVendorProfile: deleteVendorProfileSlice.reducer,
  // expense
  addExpense: addExpenseSlice.reducer,
  getAllExpense: getAllExpenseSlice.reducer,
  // transection
  getWallet: getWalletSlice.reducer,
  updateWallet: updateWalletSlice.reducer,
  getTransactionsHistory: getTransactionsHistorySlice.reducer,
  hotelWifi: hotelWifiSlice.reducer,
  // kitchen
  addKitchen: addKitchenSlice.reducer,
  getKicthen: getKitchenSlice.reducer,
  getAllKitchen: getAllKitchenSlice.reducer,
  updateKitchen: updateKitchenSlice.reducer,
  deleteKicthen: deletekitchenSlice.reducer,
  // kitchen / inventory
  getInventory: getInventorySlice.reducer,
  lostInventoryReport: lostInventoryReportSlice.reducer,
  getEmployeeOrderInQueue: getEmployeeOrderInQueueSlice.reducer,
  changeStatusOfOrder: changeStatusOfOrderSlice.reducer,
  // kitchen / inventory owner
  addOwnerKitchenInventory: addOwnerKitchenInventorySlice.reducer,
  getOwnerKitchenInventory: getOwnerKitchenInventorySlice.reducer,
  updateOwnerKitchenInventory: updateOwnerKitchenInventorySlice.reducer,
  //  kitchen / inventory stock
  getKitchenInventoryStock: getKitchenInventoryStockSlice.reducer,
  getAllKitchenInventoryStock: getKitchenInventoryStockSlice.reducer,
  updateKitchenInventoryStock: updateKitchenInventoryStockSlice.reducer,
  // kitchen // menu item
  addItemToKitchenMenu: addItemToKitchenMenuSlice.reducer,
  getAllKitchenMenuItem: getAllKitchenMenuItemSlice.reducer,
  updateKicthenMenuItem: updateKitchenMenuItemSlice.reducer,
  deletekitchenMenuItem: deletekitchenMenuItemSlice.reducer,
  // reports
  assetsReportAllTime: assetsReportAllTimeSlice.reducer,
  assetsReportAllTimeforOneAsset: assetsReportAllTimeForOneAssetSlice.reducer,
  assetsReportByDate: assetsReportByDateSlice.reducer,
  assetsReportByMonth: assetsReportByMonthSlice.reducer,
  assetsReportByYear: assetsReportByYearSlice.reducer,
  getAllBookingsOccupencyReport: getAllBookingsOccupencyReportSlice.reducer,
  bookingsOccupencyByDate: bookingsOccupencyReportByDateSlice.reducer,
  bookingsOccupencyByMonth: bookingsOccupencyReportByMonthSlice.reducer,
  bookingsOccupencyByYear: bookingsOccupencyReportByYearSlice.reducer,
  getAllBookingsSalesReport: getAllBookingsSalesReportSlice.reducer,
  bookingsSalesByDate: bookingsSalesReportByDateSlice.reducer,
  bookingsSalesByMonth: bookingsSalesReportByMonthSlice.reducer,
  bookingsSalesByYear: bookingsSalesReportByYearSlice.reducer,
  hamburger: hamburgerSlice.reducer,
  foodOrder: foodOrderSlice.reducer,
  foodMenu: getFoodMenuSlice.reducer,
  hotelServices: hotelServiceSlice.reducer,
};
