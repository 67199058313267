/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReportsCard from "./ReportsCard";
import CustomeTable from "../../components/widget/CustomeTable";
import { GoListUnordered } from "react-icons/go";
import {
  MdOutlineHomeRepairService,
  MdOutlinePendingActions,
} from "react-icons/md";
import { HiOutlineCurrencyDollar } from "react-icons/hi";

const RepairMaintenanceReports = (props) => {
  // const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const { sales } = useSelector((state) => state.SalesReport);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    props.getAssetsFromToDateAll(
      props?.hotel?._id,
      props?.fromDate,
      props?.toDate
    );
  }, [props?.fromDate, props?.toDate]);

  useEffect(() => {
    if (sales && Array.isArray(sales) && sales?.length) {
      let expense = sales?.map((d) => {
        return {
          repairName: d?.repairName,
          type: d?.type,
          description: d?.description,
          date: d?.date,
          cost: d?.cost,
        };
      });
      setDatas(expense);
    }
  }, [sales]);

  const tableData = [
    {
      _id: "RM001",
      repairName: "ROO1 Electrical",
      type: "Room Maintenance",
      description: "Description will be here",
      date: "2024-01-12",
      cost: "$500",
    },
    {
      _id: "RM002",
      repairName: "ROO1 Electrical",
      type: "Room Maintenance",
      description: "Description will be here",
      date: "2024-01-12",
      cost: "$500",
    },
    {
      _id: "RM003",
      repairName: "ROO1 Electrical",
      type: "Room Maintenance",
      description: "Description will be here",
      date: "2024-01-12",
      cost: "$500",
    },
    {
      _id: "RM004",
      repairName: "ROO1 Electrical",
      type: "Room Maintenance",
      description: "Description will be here",
      date: "2024-01-12",
      cost: "$500",
    },
    {
      _id: "RM005",
      repairName: "ROO1 Electrical",
      type: "Room Maintenance",
      description: "Description will be here",
      date: "2024-01-12",
      cost: "$500",
    },
  ];

  const columnDefs = [
    {
      headerName: "Maintenance ID",
      field: "_id",
      minWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Repair Name",
      field: "repairName",
      filter: true,
    },
    {
      headerName: "Type",
      field: "type",
      filter: true,
    },
    {
      headerName: "Description",
      field: "description",
      filter: true,
    },
    {
      headerName: "Date",
      field: "date",
      filter: true,
    },
    {
      headerName: "Cost",
      field: "cost",
      filter: true,
    },
  ];

  return (
    <>
      <div className="page-container">
        <div className="grid grid-cols-4 items-center gap-4 my-4">
          <ReportsCard
            icon={<GoListUnordered />}
            className={"bg-[#F1FADF]"}
            heading={"Total Repairs"}
            value={"1258"}
          />
          <ReportsCard
            icon={<MdOutlineHomeRepairService />}
            className={"bg-[#E8EBFF]"}
            heading={"Completed Repairs"}
            value={"800"}
          />
          <ReportsCard
            icon={<MdOutlinePendingActions />}
            className={"bg-[#FCEEDD]"}
            heading={"Pending Repair"}
            value={"1258"}
          />
          <ReportsCard
            icon={<HiOutlineCurrencyDollar />}
            className={"bg-[#FCEAEA]"}
            heading={"Total Cost"}
            value={"$1258"}
          />
        </div>

        {/* {datas?.datas?.length ? ( */}
        <CustomeTable
          tableName={"Repair Maintenance Report table"}
          columnDefs={columnDefs}
          rowData={tableData}
          setSelectedRows={setSelectedRows}
        />
        {/* ) : null} */}
      </div>
    </>
  );
};

export default RepairMaintenanceReports;
