import React from "react";
import { HiOutlinePlusSm } from "react-icons/hi";

const AddButton = ({ text, onClick, disabled = false, className }) => {
  return (
    <button
      className={`${className} py-2 px-4 min-w-[123px] w-fit h-10 flex items-center bg-base-primary transition-all duration-[0.3s] ease-[0s] hover:bg-base-primary_hover rounded-md gap-2`}
      onClick={onClick}
      type="button"
      disabled={disabled}
    >
      <div className="border border-white rounded-md p-[2px] text-white">
        <HiOutlinePlusSm />
      </div>
      <div className="text-[14px] leading-[14px] font-semibold tracking-[-0.6%] text-white whitespace-nowrap">
        {text}
      </div>
    </button>
  );
};

export default AddButton;
