import React, { useState } from "react";

import { DAYS_NAME } from "../../constants";
import CloseButton from "../../components/buttons/CloseButton";
import SelectFromObject from "../../components/form/SelectFromObject";
import MultipleSelectTabView from "../../components/form/MultipleSelectTabView";
import SubmitButton from "../../components/buttons/SubmitButton";
import { HiOutlinePlusSm } from "react-icons/hi";

const AddAssignShift = (props) => {
  const [employeeName, setEmployeeName] = useState([]);
  const [duration, setDuration] = useState([]);
  const [addMore, setAddmore] = useState([]);
  const [dutyDays, setDutyDays] = useState([]);
  const [selectShift, setSelectShift] = useState([]);

  const handleAddMore = () => {
    const addOnceMore = {
      dutyDays,
    };
    setAddmore([...addMore, addOnceMore]);
  };
  console.log("selected days :", dutyDays);

  return (
    <div className="relative w-full border border-[#E5E9EB] rounded-lg bg-white mt-6 p-6">
      <div className="text-center font-bold text-[30px] leading-[37.8px] text-[#07090D] tracking-[-1.5%]">
        Assign Shift
      </div>
      <div className="absolute right-5 top-8">
        <CloseButton onClick={props.close} />
      </div>

      <div className="bg-[#F6F6F6] rounded-[5px] mt-4 p-6">
        <div className="w-4/5">
          <div className="grid grid-cols-2 gap-6">
            <SelectFromObject
              label={"Employee Name"}
              options={{}}
              value={employeeName}
              setValue={setEmployeeName}
            />
            <SelectFromObject
              label={"Duration"}
              options={{}}
              value={duration}
              setValue={setDuration}
            />
          </div>

          <div className="grid grid-cols-2 gap-6 items-start mt-4">
            <MultipleSelectTabView
              label={`${"Select Duty Days"} *`}
              value={dutyDays}
              setValue={setDutyDays}
              options={DAYS_NAME}
              required={true}
            />
            <div className="pt-1">
              <SelectFromObject
                label={`${"Select Shift"} *`}
                options={{}}
                value={selectShift}
                setValue={setSelectShift}
              />
            </div>
          </div>
          {addMore.map((item) => (
            <div className="grid grid-cols-2 gap-6 mt-4" key={item}>
              <MultipleSelectTabView
                label={`${"Select Duty Days"} *`}
                value={dutyDays}
                setValue={setDutyDays}
                options={DAYS_NAME}
                required={true}
              />
              <div className="pt-1">
                <SelectFromObject
                  label={`${"Select Shift"} *`}
                  options={{}}
                  value={selectShift}
                  setValue={setSelectShift}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="flex items-center justify-between mt-6">
          <button
            type="button"
            onClick={handleAddMore}
            className="h-10 w-[205px] flex items-center justify-center gap-4 bg-white hover:bg-gray-200 rounded-md text-[#07090D]"
          >
            <div className="border border-white rounded-md p-[2px] text-white">
              <HiOutlinePlusSm />
            </div>
            <div className="text-[16px] leading-4 font-medium tracking-[-0.6%]">
              Add More
            </div>
          </button>
          <SubmitButton text={"Add Shift"} />
        </div>
      </div>
    </div>
  );
};

export default AddAssignShift;
