import React, { useEffect, useState } from "react";
import CustomeTable from "../../../components/widget/CustomeTable";
import { useDispatch, useSelector } from "react-redux";
import CreateRoomAmenitiesList from "./CreateRoomAmenitiesList";
import {
  deleteRoomAmenityAction,
  getRoomAmenitiesAction,
} from "../../../lib/slices/room/roomAmenitiesSlice";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../components/common/alert";
import EditAmenity from "./EditAmenity";
import AddButton from "../../../components/buttons/AddButton";
import AddToRoomAmenities from "./AddToRoomAmenities";

const RoomAmenities = () => {
  const dispatch = useDispatch();
  const { amenity, loading, error } = useSelector((state) => state.roomAmenity);
  const { hotel } = useSelector((state) => state.hotel);
  const [isEditAmenity, setIsEditAmenity] = useState(false);
  const [getEditAmenity, setGetEditAmenity] = useState(null);
  const [datas, setDatas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isCreateRoomAmenities, setIsCreateRoomAmenities] = useState(false);
  const [isAddToRoom, setIsAddToRoom] = useState(false);

  const getCategory = amenity?.map((data) => data?.listName);

  useEffect(() => {
   if(hotel?._id){
    dispatch(
      getRoomAmenitiesAction({ hotelId: hotel?._id, category: getCategory })
    );
   }
  }, []);

  useEffect(() => {
    if (amenity && Array.isArray(amenity)) {
      const getAmenities = amenity?.map((amenitiesData) => {
        return {
          _id: amenitiesData?._id,
          listName: amenitiesData?.listName,
          amenities: amenitiesData?.amenities?.map((aments) => aments?.amenity),
        };
      });
      if (getAmenities?.length) {
        setDatas(getAmenities?.reverse());
      }
    }
  }, [amenity]);

  const handleEdit = () => {
    if (!selectedRows.length) {
      notifyWarning("Please selecte at once in row to be edit.");
      return;
    }
    setIsEditAmenity(true);
    setGetEditAmenity(selectedRows[0]);
  };

  const handleDelete = () => {
    const amenityId = selectedRows.map((data) => data._id);
    if (selectedRows.length === 0) {
      notifyWarning("Please select at least one row to delete.");
      return;
    }
    if (!amenityId || selectedRows.length === 0) return;
    dispatch(deleteRoomAmenityAction({ hotelId: hotel?._id, amenityId }));

    if (!loading) {
      notifySuccess(error || "Deleted service successfully.");
    } else {
      notifyError(error || "Please Enter Valid Details.");
    }
  };

  const handleIsAddTORoom = () => {
    setIsAddToRoom(true);
    setIsCreateRoomAmenities(false);
  };

  const handleIsCreateAmenities = () => {
    setIsCreateRoomAmenities(true);
    setIsAddToRoom(false);
  };

  const columnDefs = [
    {
      headerName: "Amenities List Id",
      field: "_id",
      maxWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Room Amenities List Name",
      field: "listName",
      maxWidth: 200,
      filter: true,
    },
    {
      headerName: "Selected Amenities Name",
      field: "amenities",
      filter: true,
    },
  ];

  return (
    <div>
      <div className="flex items-center justify-end gap-4">
        <AddButton
          text={"Create Amenities List"}
          onClick={handleIsCreateAmenities}
        />
        <AddButton text={"Add To Room"} onClick={handleIsAddTORoom} />
      </div>
      {isCreateRoomAmenities ? (
        <CreateRoomAmenitiesList
          close={() => setIsCreateRoomAmenities(false)}
        />
      ) : null}

      {isEditAmenity ? (
        <EditAmenity
          editAmenityData={getEditAmenity}
          close={() => setIsEditAmenity(false)}
        />
      ) : null}

      {isAddToRoom ? (
        <AddToRoomAmenities close={() => setIsAddToRoom(false)} />
      ) : null}

      <div className="mt-4">
        <CustomeTable
          tableName={"Room Amenities List table"}
          size="h-[55vh]"
          rowData={datas}
          setSelectedRows={setSelectedRows}
          columnDefs={columnDefs}
          editFunc={handleEdit}
          deleteFunc={handleDelete}
        />
      </div>
    </div>
  );
};

export default RoomAmenities;
