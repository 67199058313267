import React, { useState } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import DeleteOrNot from "../modals/DeleteOrNot";

const DeleteButton = ({
  onClick = () => {},
  loading = false,
  text = "Delete",
  className = "",
}) => {
  const [isDialogOpen,setIsDialogOpen] = useState(false)
  const handleClick = () => {
    setIsDialogOpen(true); // Show confirmation dialog
  };

  const handleConfirm = () => {
    onClick(); // Call the provided submit function
  };
  return (
    <div>
      <button
        className={`${className} h-10 px-4 border border-[#F04438] rounded-md flex items-center justify-center gap-3 duration-300 ease-[0.3s] bg-white text-[#F04438] text-sm font-semibold hover:text-white hover:bg-[#F04438] group`}
        onClick={handleClick}
        disabled={loading}
        type="button"
      >
        <div className="flex items-center justify-center rounded text-white bg-[#F04438] group-hover:bg-white group-hover:text-[#F04438] p-[3px]">
          <RiDeleteBin5Line size={16} />
        </div>
        <span className="tracking-[-0.006em] text-[14px] leading-6">
          {text}
        </span>
      </button>
      <DeleteOrNot
        isOpen={isDialogOpen}
        onClose={()=>setIsDialogOpen(false)}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default DeleteButton;
