import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReportsCard from "./ReportsCard";
import CustomeTable from "../../components/widget/CustomeTable";
import { MdOutlineBedroomParent } from "react-icons/md";
import { BsHouseLock } from "react-icons/bs";
import { GiKeyring } from "react-icons/gi";

const OccupancyReport = (props) => {
  // const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const { sales } = useSelector((state) => state.SalesReport);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    //get booking sales by date
    if (props?.fromDate && props?.toDate) {
      props?.byDateOccupancy(props?.hotel?._id, props?.fromDate, props?.toDate);
    }
    // get booking sales by month
    else if (props?.fromMonth && props?.toMonth) {
      props?.byMonthOccupancy(
        props?.hotel?._id,
        props?.fromMonth,
        props?.toMonth
      );
    }
    //get booking sales by year
    else if (props?.fromYear && props?.toYear) {
      props?.byYearOccupancy(props?.hotel?._id, props?.fromYear, props?.toYear);
    }
    //get all booking sales
    else if (!props?.fromDate && !props?.fromMonth && !props?.fromYear) {
      props?.getOccupancies(props?.hotel?._id);
    } else return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.fromDate, props?.toDate]);

  useEffect(() => {
    if (sales && Array.isArray(sales) && sales?.length) {
      let expense = sales?.map((d) => {
        return {
          roomNumber: d?.roomNumber,
          roomType: d?.roomType,
          guestName: d?.guestName,
          checkInDate: d?.checkInDate,
          checkOutDate: d?.checkOutDate,
          revenue: d?.revenue,
        };
      });
      setDatas(expense);
    }
  }, [sales]);

  const tableData = [
    {
      id: "RM001",
      roomNumber: "101",
      roomType: "Deluxe",
      guestName: "Alex",
      checkInDate: "2024-01-11",
      checkOutDate: "2024-01-11",
      revenue: "$400",
    },
    {
      id: "RM002",
      roomNumber: "101",
      roomType: "Deluxe",
      guestName: "Alex",
      checkInDate: "2024-01-11",
      checkOutDate: "2024-01-11",
      revenue: "$300",
    },
    {
      id: "RM003",
      roomNumber: "101",
      roomType: "Deluxe",
      guestName: "Alex",
      checkInDate: "2024-01-11",
      checkOutDate: "2024-01-11",
      revenue: "$200",
    },
    {
      id: "RM004",
      roomNumber: "101",
      roomType: "Deluxe",
      guestName: "Alex",
      checkInDate: "2024-01-11",
      checkOutDate: "2024-01-11",
      revenue: "$1000",
    },
    {
      id: "RM005",
      roomNumber: "101",
      roomType: "Deluxe",
      guestName: "Alex",
      checkInDate: "2024-01-11",
      checkOutDate: "2024-01-11",
      revenue: "$500",
    },
  ];

  const columnDefs = [
    {
      headerName: "Room ID",
      field: "id",
      minWidth: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Room Number",
      field: "roomNumber",
      filter: true,
    },
    {
      headerName: "Room Type",
      field: "roomType",
      filter: true,
    },
    {
      headerName: "Guest Name",
      field: "guestName",
      filter: true,
    },
    {
      headerName: "Check-In-Date",
      field: "checkInDate",
      filter: true,
    },
    {
      headerName: "Check-Out-Date",
      field: "checkOutDate",
      filter: true,
    },
    {
      headerName: "Revenue",
      field: "revenue",
      filter: true,
    },
  ];

  return (
    <div className="page-container">
      <div className="my-4 grid grid-cols-3 items-center gap-4">
        <ReportsCard
          icon={<MdOutlineBedroomParent />}
          className={"bg-[#FFF6ED]"}
          heading={"Total Rooms"}
          value={"1258"}
        />
        <ReportsCard
          icon={<BsHouseLock />}
          className={"bg-[#F9F5FF]"}
          heading={"Total Booking"}
          value={"800"}
        />
        <ReportsCard
          icon={<GiKeyring />}
          className={"bg-[#F9F5FF]"}
          heading={"Available Rooms"}
          value={"425"}
        />
      </div>
      {/* {datas?.datas?.length ? ( */}
      <CustomeTable
        tableName={"Room Occupancy Report table"}
        columnDefs={columnDefs}
        rowData={tableData}
        setSelectedRows={setSelectedRows}
      />
      {/* ) : null} */}
    </div>
  );
};
export default OccupancyReport;
