import React, { useState } from "react";
import CustomModal from "../../../components/modals/CustomModal";
import { updateMinibarItemAction } from "../../../lib/slices/room/roomMinibarItemSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../components/common/alert";
import Inputfield from "../../../components/form/InputField";
import SelectFromObject from "../../../components/form/SelectFromObject";
import {
  DIETARY_PREFERENCE,
  FOOD_BEVERAGE_TYPES,
  MEASUREMENT_UNITS,
  QUANTITY_PER_ITEM,
} from "../../../constants";
import UnitsMeassureInputfield from "../../../components/form/UnitsMeassureInputfield";
import FormSubmitButton from "../../../components/buttons";

const EditMinibarItem = ({ close, editItem }) => {
  const dispatch = useDispatch();
  const { hotel } = useSelector((state) => state.hotel);
  const { loading, error } = useSelector((state) => state.minibarItem);

  const [formData, setFormData] = useState({
    foodAndbevragesName: editItem?.foodAndBeveragesName,
    fAndBType: editItem?.fAndBType,
    dietaryPreference: editItem?.dietaryPreference,
    quantity: editItem?.quantity,
    measurementUnit: editItem?.quantityMeasure,
    price: editItem?.price,
    measurementUnitPerItem: editItem?.measurementUnitPerItem,
  });

  const handleSubmit = () => {
    const minibarData = {
      itemId: editItem?.itemId,
      hotelId: hotel?._id,
      itemName: formData?.foodAndbevragesName,
      itemType: formData?.fAndBType,
      dietaryPreference: formData?.dietaryPreference,
      quantity: {
        netQuantity: formData?.quantity,
        measurementUnit: formData?.measurementUnit,
      },
      price: {
        netPrice: Number(formData?.price),
        measurementUnitPerItem: formData?.measurementUnitPerItem,
        currency: hotel?.currency,
      },
    };

    if (!formData.foodAndbevragesName) {
      notifyWarning(error || "Please Enter All Fields are required.");
      return;
    }

    if (hotel?._id) {
      dispatch(updateMinibarItemAction(minibarData));
    }
    if (!loading) {
      notifySuccess("Minibar item Edit successfully.");
      close();
    } else {
      notifyError(error || "Please enter valid details.");
    }
  };

  return (
    <CustomModal close={close} heading="Edit Minibar Item">
      <div className="w-[60vw] grid grid-cols-3 gap-6 my-6">
        <Inputfield
          label={"Food & Beverages Name"}
          value={formData.foodAndbevragesName}
          onChange={(e) =>
            setFormData({ ...formData, foodAndbevragesName: e.target.value })
          }
        />

        <SelectFromObject
          label={"F&B Type"}
          options={FOOD_BEVERAGE_TYPES}
          value={formData.fAndBType}
          setValue={(value) => setFormData({ ...formData, fAndBType: value })}
        />

        <SelectFromObject
          label={"Dietary Preference"}
          options={DIETARY_PREFERENCE}
          value={formData.dietaryPreference}
          setValue={(value) =>
            setFormData({ ...formData, dietaryPreference: value })
          }
        />

        <UnitsMeassureInputfield
          label={"Quantity"}
          value={formData.quantity}
          selectValue={formData.measurementUnit}
          onChange={(e) =>
            setFormData({ ...formData, quantity: e.target.value })
          }
          options={MEASUREMENT_UNITS}
          setSelectValue={(value) =>
            setFormData({ ...formData, measurementUnit: value })
          }
          minLength={0}
        />

        <UnitsMeassureInputfield
          label={"Price"}
          value={formData.price}
          selectValue={formData.measurementUnitPerItem}
          onChange={(e) => setFormData({ ...formData, price: e.target.value })}
          options={QUANTITY_PER_ITEM}
          setSelectValue={(value) =>
            setFormData({ ...formData, measurementUnitPerItem: value })
          }
          minLength={0}
        />
      </div>
      <div className="flex items-center justify-end">
        <FormSubmitButton
          buttonText="Update Minibar"
          onSubmit={handleSubmit}
          loading={loading}
        />
      </div>
    </CustomModal>
  );
};

export default EditMinibarItem;
