import React, { useState } from "react";
import SelectFromArray from "../../../components/form/SelectFromArray";
import { BOOKING_ROOM_TYPES } from "../../../constants";
import SelectFromObject from "../../../components/form/SelectFromObject";
import EditButton from "../../../components/buttons/EditButton";
import DeleteButton from "../../../components/buttons/DeleteButton";

const SelectConnectedRoom = () => {
  const [roomNo, setRoomNo] = useState([]);
  const [roomName, setRoomName] = useState([]);
  const [toConnectRoomNo, setToConnectRoomNo] = useState([]);
  const [toConnectRoomName, setToConnectRoomName] = useState([]);

  const DUMMYROOM = [101, 102, 103, 104, 105, 106];

  return (
    <div className="w-full flex items-end justify-between gap-12 border-t border-[#E5E9EB] py-6 mt-4">
      <div className="grid grid-cols-5 w-[85%] gap-4">
        <SelectFromArray
          label={"Room No"}
          value={roomNo}
          onChange={(e) => setRoomNo(e.target.value)}
          options={DUMMYROOM}
          className={"h-[35px]"}
        />
        <SelectFromObject
          label={"Room Name"}
          value={roomName}
          setValue={setRoomName}
          options={BOOKING_ROOM_TYPES}
          className={"bg-[#F6F6F6] h-[35px]"}
        />

        <div className="flex items-center justify-center text-[14px] leading-6 font-medium tracking-[-0.6%] text-[#000000] pt-5">
          Is Connected With
        </div>

        <SelectFromArray
          label={"Room No"}
          value={toConnectRoomNo}
          onChange={(e) => setToConnectRoomNo(e.target.value)}
          options={DUMMYROOM}
          className={"h-[35px]"}
        />
        <SelectFromObject
          label={"Room Name"}
          value={toConnectRoomName}
          setValue={setToConnectRoomName}
          options={BOOKING_ROOM_TYPES}
          className={"bg-[#F6F6F6] h-[35px]"}
        />
      </div>
      {/*button */}
      <div className="flex items-end gap-4">
        <EditButton className="h-8" />
        <DeleteButton className="h-8" />
      </div>
    </div>
  );
};

export default SelectConnectedRoom;
