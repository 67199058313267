import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import RefreshLoader from "../components/common/loader/RefreshLoader";
import TopBar from "../components/layout/TopBar";
import ApiCallsBefore from "./ApiCallsBefore";
import SideBar from "../components/layout/SideBar";
import { propertyConfiguration } from "../utils/check";
import { setAvailableRooms } from "../utils/setAvailableRooms";
import { ISADMIN, ONBOARDING_STAGES, PROPERTY_ID, ROUTES } from "../constants";
import { getAllHotelsAction, getHotelByIdAction } from "../lib/slices/hotel/hotelSlice";
import { employeeAuthAction, ownerAuthAction } from "../lib/slices/auth/authSlice";

const AdminAndEmployeeRoute = () => {
  const [configurePercentage, setConfigurePercentage] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { hamburger } = useSelector((state) => state.hamburger);
  const { user, loading } = useSelector((state) => state.user);
  const { isAuthenticated, loading: authLoading } = useSelector((state) => state.auth);
  const { hotel, loading: hotelLoading } = useSelector((state) => state.hotel);
  const { bookings } = useSelector((state) => state.getBookingByConditions);
  const { rooms } = useSelector((state) => state.hotelRooms);

  // Check if app is fully loaded
  const isAppLoading = authLoading || loading || (!hotel && hotelLoading);

  // Auth check // get hotel
  useEffect(() => {
    const isadmin = localStorage.getItem(ISADMIN);
    if (isAuthenticated) {
      dispatch(isadmin ? ownerAuthAction() : employeeAuthAction());
      const hotelId = localStorage.getItem(PROPERTY_ID);
      if (!hotelId) {
        dispatch(getAllHotelsAction())
      } else {
        dispatch(getHotelByIdAction(hotelId));
      }
    } else {
      navigate(ROUTES.LOGIN)
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && !isAppLoading) {
      if (hotel) {
        const percentage = propertyConfiguration(hotel, user, []);
        setConfigurePercentage(percentage);
      } else if (!hotel || hotel?.stage !== ONBOARDING_STAGES.DASHBOARD) {
        navigate(ROUTES.ONBOARDING);
      }
    }
  }, [hotel?._id]);

  useEffect(() => {
    if (bookings && rooms) {
      setAvailableRooms(rooms, bookings);
    }
  }, [bookings, rooms]);

  return (
    <>
      <ApiCallsBefore />
      {isAppLoading ? (
        <RefreshLoader />
      ) : (
        <div className="h-screen flex flex-col bg-[#fafafa]">
          <TopBar configurePercentage={configurePercentage} />
          <div className="flex flex-1 overflow-hidden">
            <SideBar />
            <div
              className={`w-full relative duration-300 overflow-y-auto p-4 overflow-x-hidden mx-auto`}
            >
              <Outlet />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminAndEmployeeRoute;
