import React, { useState } from "react";
import { SERVICE_TYPE } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { updateServiceAction } from "../../../lib/slices/room/roomService";
import CustomModal from "../../../components/modals/CustomModal";
import Inputfield from "../../../components/form/InputField";
import SelectFromObject from "../../../components/form/SelectFromObject";
import InputTextArea from "../../../components/form/InputTextArea";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../components/common/alert";
import FormSubmitButton from "../../../components/buttons";

const EditService = ({ close, editServiceData }) => {
  const dispatch = useDispatch();
  const { hotel } = useSelector((state) => state.hotel);
  const { loading, error } = useSelector((state) => state.roomService);
  const [formData, setFormData] = useState({
    serviceName: editServiceData?.serviceName || "",
    serviceType: editServiceData?.serviceType || "",
    servicePrice: editServiceData?.servicePrice || "",
    serviceTimeStart: editServiceData?.serviceTimeStart || "",
    serviceTimeEnd: editServiceData?.serviceTimeEnd || "",
    description: editServiceData?.description || "",
  });

  const handleSubmit = () => {
    const updateformData = {
      serviceId: editServiceData?._id,
      hotelId: hotel?._id,
      name: formData.serviceName,
      category: formData.serviceType.toString(),
      charges: {
        netPrice: Number(formData.servicePrice),
        currency: hotel?.currency || "",
      },
      hours: { start: formData.serviceTimeStart, end: formData.serviceTimeEnd },
      description: formData.description,
    };

    if (
      !formData?.serviceName ||
      !formData?.serviceType ||
      formData?.serviceType.length === 0
    ) {
      notifyWarning(
        error ||
          "Please provide a valid service name and at least one service type and service Time."
      );
      return;
    }

    if (hotel?._id && editServiceData?._id) {
      dispatch(updateServiceAction(updateformData));
    }
    if (!loading) {
      notifySuccess(error || "Service updated Succesffully.");
      close();
    } else {
      notifyError(error || "Please Enter valid details.");
    }
  };

  return (
    <CustomModal close={close} heading="Edit Service">
      <div>
        <div className="grid grid-cols-3 gap-6 mt-6">
          <Inputfield
            label="Service Name"
            value={formData.serviceName}
            onChange={(e) =>
              setFormData({ ...formData, serviceName: e.target.value })
            }
            min={3}
            max={30}
          />
          <SelectFromObject
            label="Service Type"
            options={SERVICE_TYPE}
            value={formData.serviceType}
            setValue={(value) =>
              setFormData({ ...formData, serviceType: value })
            }
          />
          <Inputfield
            label="Service Price"
            placeholder="Enter Service Price"
            value={formData.servicePrice}
            onChange={(e) =>
              setFormData({ ...formData, servicePrice: e.target.value })
            }
          />
        </div>
        <div className="text-[20px] font-bold my-4">Service Timings</div>
        <div className="grid grid-cols-3 gap-6">
          <Inputfield
            label="Start"
            type="time"
            value={formData.serviceTimeStart}
            onChange={(e) =>
              setFormData({ ...formData, serviceTimeStart: e.target.value })
            }
          />
          <Inputfield
            label="End"
            type="time"
            value={formData.serviceTimeEnd}
            onChange={(e) =>
              setFormData({ ...formData, serviceTimeEnd: e.target.value })
            }
          />
        </div>
        <div className="mt-4">
          <InputTextArea
            rows={3}
            label="Description"
            placeholder="Note..."
            value={formData.description}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
            minLength={10}
            maxLength={500}
          />
        </div>
        <div className="flex justify-end mt-4">
          <FormSubmitButton
            buttonText={"Update"}
            onSubmit={handleSubmit}
            loading={loading}
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default EditService;
