import React, { useEffect, useState } from "react";
import CustomeTable from "../../../components/widget/CustomeTable";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteServiceAction,
  getServicesAction,
} from "../../../lib/slices/room/roomService";
import { notifySuccess, notifyWarning } from "../../../components/common/alert";
import { toCapitalize } from "../../../middlewares/toCapitalize";
import EditService from "./EditService";

const AllServices = () => {
  const dispatch = useDispatch();
  const { service, error, loading } = useSelector((state) => state.roomService);
  const { hotel } = useSelector((state) => state.hotel);
  const [datas, setDatas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isEditService, setIsEditService] = useState(false);
  const [getUpdateService, setGetUpdateService] = useState(null);

  useEffect(() => {
    if (hotel?._id) {
      dispatch(getServicesAction({ hotelId: hotel?._id }));
    }
  }, []);

  useEffect(() => {
    if (service && Array.isArray(service)) {
      const data = service?.map((serviceData) => {
        const { _id, name, category, charges, hours, description } =
          serviceData;
        return {
          serviceName: toCapitalize(name),
          serviceType: toCapitalize(category),
          servicePrice: charges?.netPrice,
          serviceTimeStart: hours?.start,
          serviceTimeEnd: serviceData?.hours?.end,
          description: toCapitalize(description),
          _id,
        };
      });
      if (data?.length) {
        setDatas(data?.reverse());
      }
    }
  }, [service]);

  const handleEdit = () => {
    // Exit if no rows are selected
    if (!selectedRows.length) {
      notifyWarning("Please selecte at once in row to be edit.");
      return;
    }
    setIsEditService(true);
    setGetUpdateService(selectedRows[0]);
  };

  const handleDelete = () => {
    const serviceId = selectedRows.map((service) => service._id);
    if (selectedRows.length === 0) {
      notifyWarning("Please select at least one row to delete.");
      return;
    }

    if (!serviceId || selectedRows.length === 0) return;
    dispatch(deleteServiceAction({ hotelId: hotel?._id, serviceId }));

    if (!loading) {
      notifySuccess(error || "Deleted service successfully.");
    }
  };

  const columnDefs = [
    {
      headerName: "Service Name",
      field: "serviceName",
      minWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Service Type",
      field: "serviceType",
      filter: true,
    },
    {
      headerName: "Service Price",
      field: "servicePrice",
      filter: true,
    },
    {
      headerName: "Service Time Start",
      field: "serviceTimeStart",
      filter: true,
    },
    {
      headerName: "Service Time End",
      field: "serviceTimeEnd",
      filter: true,
    },
    {
      headerName: "Description",
      field: "description",
      filter: true,
    },
  ];

  return (
    <>
      {isEditService ? (
        <EditService
          editServiceData={getUpdateService}
          close={() => setIsEditService(false)}
        />
      ) : null}

      <CustomeTable
        size="h-[55vh]"
        tableName={"All Services List Table"}
        rowData={datas}
        setSelectedRows={setSelectedRows}
        columnDefs={columnDefs}
        editFunc={handleEdit}
        deleteFunc={handleDelete}
      />
    </>
  );
};

export default AllServices;
