import React from "react";
import { registerEvent } from "../../logs";
import { useTranslation } from "react-i18next";
import CustomModal from "./CustomModal";
import CancelButton from "../buttons/CancelButton";
import SubmitButton from "../buttons/SubmitButton";

export default function SaveOrNot({ onConfirm, isOpen, onClose }) {
  const { t } = useTranslation();
  if (!isOpen) return null;
  
  //agree to delete
  const agree = () => {
    onConfirm();
    onClose();
    registerEvent("agree to delete", "delete or not delete modal");
  };

  //disagree to delete
  const disagree = () => {
    onClose();
    registerEvent("disagree to delete", "delete or not delete modal");
  };

  return (
    <>
      <CustomModal  heading={"Please Confirm Changes"} isCloseButton={false}>
        <div className="w-[20vw]">
         
          <p className="text-[14px] leading-[26px] font-medium text-[#6E7C87]">
            {t("1117")}
          </p>
          <div className="flex items-center justify-between mt-6">
            <CancelButton onClick={disagree} />
            <SubmitButton
              type="button"
              text={t("87")}
              sizing={"w-20 h-[37px]"}
              onClick={agree}
            />
          </div>
        </div>
      </CustomModal>
    </>
  );
}
