import React from "react";
import ToolTip from "../common/ToolTip";

const InputTextArea = ({
  label,
  value,
  rows = 2,
  onChange,
  maxLength,
  minLength,
  message,
  placeholder,
  required = false,
  readOnly = false,
  placeholderColor = false,
  className,
}) => {
  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <label className="text-sm font-semibold text-[#344054]">{label}</label>
        <ToolTip Message={message} />
      </div>
      <textarea
        className={`border border-[#D0D5DD] w-full text-sm p-3 rounded-md focus:border-base-primary resize-none overflow-y-auto scroll-barguest text-[16px] leading-6 ${
          placeholderColor && "placeholder:text-base-primary"
        } ${className}`}
        rows={rows}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        minLength={minLength}
        placeholder={placeholder}
        required={required}
        readOnly={readOnly}
      />
    </div>
  );
};

export default InputTextArea;
