import React from "react";
import ToolTip from "../common/ToolTip";

const SelectFromObject = ({
  label,
  value,
  setValue,
  options,
  toolTipMessage,
  required = false,
  className,
  optionalText = false
}) => {
  // typeof options === object & key for backend , value for frontend
  return (
    <div className="w-full">
      <div className={`flex items-center justify-between capitalize`}>
        <label>{label} {optionalText ? (
            <span className="text-[12px] leading-5 font-medium">
              {optionalText}
            </span>
          ) : null}</label>
        <ToolTip Message={toolTipMessage} />
      </div>
      <div className={`relative flex items-center`}>
        <select
          value={value}
          onChange={ (e) => setValue(e.target.value)}
          className={`capitalize ${className}`}
          required={required}
        >
          {Object.keys(options)?.map((item) => (
            <option value={item} key={item} className="capitalize">
              {options[item].replace("_", " ").toLowerCase()}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SelectFromObject;
