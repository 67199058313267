import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authAxios from "../../services/authAxios";

//get all Minibar items action
export const getAllMinibarItemsAction = createAsyncThunk(
  "room/getAllMinibarItemsAction",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId } = credentials;
      if (!hotelId) return;
      const url = `api/hotel/owner/rooms/minibar/items?hotelId=${hotelId}`;
      const response = await authAxios.get(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//get a Minibar item action
export const getMinibarItemAction = createAsyncThunk(
  "room/getMinibarItemAction",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId, itemId } = credentials;
      if (!hotelId && !itemId) return;
      const url = `api/hotel/owner/rooms/minibar/item?hotelld=${hotelId}&itemId=${itemId}`;
      const response = await authAxios.get(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// add minibar item action
export const addMinibarItemAction = createAsyncThunk(
  "room/addMinibarItemAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `api/hotel/owner/rooms/minibar/item`;
      const response = await authAxios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// update minibar item action
export const updateMinibarItemAction = createAsyncThunk(
  "room/updateMinibarItemAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `api/hotel/owner/rooms/minibar/item`;
      const response = await authAxios.put(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// delete service action
export const deleteMinibarItemAction = createAsyncThunk(
  "room/deleteMinibarItemAction",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId, itemId } = credentials;
      const url = `api/hotel/owner/rooms/minibar/item`;
      const response = await authAxios.delete(url, {
        data: { hotelId, itemId },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Helper function for handling pending state
const handlePending = (state) => {
  state.loading = true;
  state.error = null;
};

// Helper function for handling fulfilled state
const handleFulfilled = (state, action) => {
  state.loading = false;
  state.minibarItem = action.payload;
};

// Helper function for handling rejected state
const handleRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload || "An error occurred";
};

export const roomMinibarItemSlice = createSlice({
  name: "room/roomMinibarItemSlice",
  initialState: {
    loading: false,
    minibarItem: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMinibarItemsAction.pending, handlePending)
      .addCase(getAllMinibarItemsAction.fulfilled, handleFulfilled)
      .addCase(getAllMinibarItemsAction.rejected, handleRejected)
      .addCase(getMinibarItemAction.pending, handlePending)
      .addCase(getMinibarItemAction.fulfilled, handleFulfilled)
      .addCase(getMinibarItemAction.rejected, handleRejected)
      .addCase(addMinibarItemAction.pending, handlePending)
      .addCase(addMinibarItemAction.fulfilled, handleFulfilled)
      .addCase(addMinibarItemAction.rejected, handleRejected)
      .addCase(updateMinibarItemAction.pending, handlePending)
      .addCase(updateMinibarItemAction.fulfilled, handleFulfilled)
      .addCase(updateMinibarItemAction.rejected, handleRejected)
      .addCase(deleteMinibarItemAction.pending, handlePending)
      .addCase(deleteMinibarItemAction.fulfilled, handleFulfilled)
      .addCase(deleteMinibarItemAction.rejected, handleRejected);
  },
});
