import React, { useState } from "react";
import Inputfield from "../../../components/form/InputField";
import AddRoomTypeCheckBox from "../RoomTypeCheckBox";
import { useTranslation } from "react-i18next";
import SelectFromArray from "../../../components/form/SelectFromArray";
import { BED_TYPES } from "../../../constants";
import { useSelector } from "react-redux";
import ViewAmenitiesList from "./ViewAmenitiesList";
import ViewMinibarList from "./ViewMinibarList";
import SelectFromObject from "../../../components/form/SelectFromObject";

const RoomByNumber = ({ roomNo, roomsData, handleChangeRooms, index }) => {
  const { t } = useTranslation();
  const { amenity } = useSelector((state) => state.roomAmenity);
  const { minibar } = useSelector((state) => state.minibar);
  const getAmenitesList = amenity?.map((data) => data?.listName);
  const getMinibarList = minibar?.map((data) => data?.minibar);
  const [isViewAmenitiesList, setIsViewAmenitiesList] = useState(false);
  const [isViewMinibarList, setIsViewMinibarList] = useState(false);

  const CHOSE_BEDS_SIZES = {
    ALL_BEDS_DIFFRENT_SIZE: "ALL BEDS DIFFRENT SIZE",
    ALL_BEDS_SAME_SIZE: "ALL BEDS SAME SIZE",
  };

  const handleViewAmenitiesList = () => {
    setIsViewAmenitiesList(true);
  };

  const handleViewMinibarList = () => {
    setIsViewMinibarList(true);
  };

  return (
    <div className="w-full">
      <div className="text-[18px] leading-5 font-medium">{`Room ${roomNo}`}</div>
      <div className="w-full flex gap-3 items-start mt-2">
        <div className="w-[60%] grid grid-cols-2 gap-4">
          <div className="flex gap-3">
            <Inputfield
              className="text-[14px] leading-6 font-normal text-[#667085] px-3"
              label={`Room Number`}
              message={"abc"}
              value={roomsData[index].roomNum}
              onChange={(e) =>
                handleChangeRooms(index, "roomNum", e.target.value)
              }
              placeholder={"Enter Number "}
            />
            <Inputfield
              className="text-[#667085]"
              label={`Number of Beds`}
              type="number"
              value={roomsData[index].numberOfBeds || "1"}
              onChange={(e) =>
                handleChangeRooms(index, "numberOfBeds", e.target.value)
              }
              min={1}
              message={"abc"}
            />
          </div>
          <SelectFromObject
            label={"Bed Size"}
            className="text-[14px] leading-6 font-normal text-[#667085] px-3"
            value={roomsData[index].allBedSizes || "ALL BEDS DIFFRENT SIZE"}
            setValue={(value) => handleChangeRooms(index, "allBedSizes", value)}
            options={CHOSE_BEDS_SIZES}
          />
          {[...Array(+roomsData[index].numberOfBeds || 1)].map(
            (_, bedIndex) => (
              <div key={bedIndex}>
                <SelectFromObject
                  className="text-[14px] leading-6 font-normal text-[#667085] px-3"
                  label={`Bed ${bedIndex + 1}`}
                  value={
                    roomsData[index].allBedSizes === "ALL_BEDS_SAME_SIZE"
                      ? roomsData[index]?.bedTypes[0] || "SINGLE"
                      : roomsData[index]?.bedTypes[bedIndex] || "SINGLE"
                  }
                  options={BED_TYPES}
                  setValue={(value) => {
                    const updatedTypes = [...roomsData[index]?.bedTypes];

                    if (roomsData[index].allBedSizes === "ALL_BEDS_SAME_SIZE") {
                      // Set all beds to the same type if "ALL_BEDS_SAME_SIZE" is selected
                      updatedTypes.fill(value);
                    } else {
                      // Set the type for a specific bed
                      updatedTypes[bedIndex] = value;
                    }

                    handleChangeRooms(index, "bedTypes", updatedTypes);
                  }}
                />
              </div>
            )
          )}
        </div>

        {/* Room Preferences */}
        <div className="w-[40%] flex gap-3">
          <div className="w-[35%] flex flex-col justify-center">
            <div className="flex flex-col items-start gap-1 mx-auto">
              <div className="text-[14px] leading-[14px] font-medium text-[#07090D]">
                Room Preference
              </div>
              <AddRoomTypeCheckBox
                label={t("85")}
                value={roomsData[index].isAcRoom || false}
                setValue={(value) =>
                  handleChangeRooms(index, "isAcRoom", value)
                }
              />
              <AddRoomTypeCheckBox
                label={t("86")}
                value={roomsData[index].isBalcony || false}
                setValue={(value) =>
                  handleChangeRooms(index, "isBalcony", value)
                }
              />
              <AddRoomTypeCheckBox
                label={t("519")}
                value={roomsData[index].isSmoking || false}
                setValue={(value) =>
                  handleChangeRooms(index, "isSmoking", value)
                }
              />
            </div>
          </div>
          {/* Amenities and minibar */}
          <div className="w-[65%] flex flex-col gap-3">
            <div className="w-full flex items-center">
              <div className="w-[75%]">
                <SelectFromArray
                  className="text-[16px] leading-6 font-normal text-[#667085] px-2"
                  label={"Select Room Amenities List"}
                  value={roomsData[index].roomAmenitiesList}
                  onChange={(e) =>
                    handleChangeRooms(
                      index,
                      "roomAmenitiesList",
                      e.target.value
                    )
                  }
                  options={getAmenitesList}
                />
              </div>
              <button
                type="button"
                onClick={handleViewAmenitiesList}
                className="ml-2 h-fit w-fit text-[10px] leading-6 font-medium text-base-primary hover:text-base-primary_hover duration-300 ease-[0.3s] underline hover:no-underline mt-4"
              >
                View List
              </button>
            </div>
            {isViewAmenitiesList && (
              <ViewAmenitiesList close={() => setIsViewAmenitiesList(false)} />
            )}
            <div className="w-full flex items-center">
              <div className="w-[75%] ">
                <SelectFromArray
                  className="text-[16px] leading-6 font-normal text-[#667085] px-2"
                  label={"Select Room Minibar List"}
                  value={roomsData[index].roomMiniBarList || ""}
                  onChange={(e) =>
                    handleChangeRooms(index, "roomMiniBarList", e.target.value)
                  }
                  options={getMinibarList}
                />
              </div>
              <button
                type="button"
                onClick={handleViewMinibarList}
                className="ml-2 h-fit w-fit text-[10px] leading-6 font-medium text-base-primary hover:text-base-primary_hover duration-300 ease-[0.3s] underline hover:no-underline mt-4"
              >
                View List
              </button>
            </div>
            {isViewMinibarList && (
              <ViewMinibarList close={() => setIsViewMinibarList(false)} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomByNumber;
