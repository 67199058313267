import React from "react";
import CloseButton from "../../../components/buttons/CloseButton";
import SubmitButton from "../../../components/buttons/SubmitButton";
import SelectConnectedRoom from "./SelectConnectedRoom";

const ConnectedRooms = ({ close = () => {} }) => {
  return (
    <div className="w-full mt-4 border border-[] rounded-[10px] bg-white p-6">
      <div className="flex items-center justify-between text-[20px] leading-6 font-medium capitalize">
        Select Connected Rooms
        <CloseButton onClick={close} />
      </div>
      <div className="">
        {Array.from({ length: 2 }).map((index) => (
          <SelectConnectedRoom key={index} />
        ))}
      </div>
      <div className="flex items-center justify-end mt-4">
        <SubmitButton
          sizing={"h-[35px] w-[186px]"}
          text={"Connect Rooms"}
          onClick={""}
        />
      </div>
    </div>
  );
};

export default ConnectedRooms;
