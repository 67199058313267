import React, { useState } from "react";
import { getToday } from "../../utils";
import SubmitButton from "../../components/buttons/SubmitButton";
import CustomeTable from "../../components/widget/CustomeTable";
import { GroupCellRenderer } from "ag-grid-community";

const QrCodeHistory = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [date, setDate] = useState(getToday());

  const qrCodeImage = (params) => {
    return (
      // first save qrcode file in svg then fetch svg to display
      <div className="h-full flex items-center">
        <div className="size-8 border-[0.5px] rounded-sm">
          <img
            src={`https://w7.pngwing.com/pngs/144/829/png-transparent-qr-code-information-qr-code-android-qrcode-text-rectangle-monochrome.png`}
            alt=""
            className="object-cover"
          />
        </div>
      </div>
    );
  };

  const tableData = [
    {
      qrCodeImg: "images",
      qrCodeId: "QA0001",
      roomNo: "123",
      roomType: "Deluxe Room",
      noOfScanByGuest: "35",
      status: "Inactive",
      issueDataAndTime: "20-01-2024 | 11:35am",
      cancelDataAndTime: "20-01-2024 | 11:35am",
    },
    {
      qrCodeImg: "images",
      qrCodeId: "QA0001",
      roomNo: "123",
      roomType: "Deluxe Room",
      noOfScanByGuest: "35",
      status: "Inactive",
      issueDataAndTime: "20-01-2024 | 11:35am",
      cancelDataAndTime: "20-01-2024 | 11:35am",
    },

    {
      qrCodeImg: "images",
      qrCodeId: "QA0001",
      roomNo: "123",
      roomType: "Deluxe Room",
      noOfScanByGuest: "35",
      status: "Inactive",
      issueDataAndTime: "20-01-2024 | 11:35am",
      cancelDataAndTime: "20-01-2024 | 11:35am",
    },
    {
      qrCodeImg: "images",
      qrCodeId: "QA0001",
      roomNo: "123",
      roomType: "Deluxe Room",
      noOfScanByGuest: "35",
      status: "Inactive",
      issueDataAndTime: "20-01-2024 | 11:35am",
      cancelDataAndTime: "20-01-2024 | 11:35am",
    },
    {
      qrCodeImg: "images",
      qrCodeId: "QA0001",
      roomNo: "123",
      roomType: "Deluxe Room",
      noOfScanByGuest: "35",
      status: "Inactive",
      issueDataAndTime: "20-01-2024 | 11:35am",
      cancelDataAndTime: "20-01-2024 | 11:35am",
    },
    {
      qrCodeImg: "images",
      qrCodeId: "QA0001",
      roomNo: "123",
      roomType: "Deluxe Room",
      noOfScanByGuest: "35",
      status: "Inactive",
      issueDataAndTime: "20-01-2024 | 11:35am",
      cancelDataAndTime: "20-01-2024 | 11:35am",
    },
    {
      qrCodeImg: "images",
      qrCodeId: "QA0001",
      roomNo: "123",
      roomType: "Deluxe Room",
      noOfScanByGuest: "35",
      status: "Inactive",
      issueDataAndTime: "20-01-2024 | 11:35am",
      cancelDataAndTime: "20-01-2024 | 11:35am",
    },
    {
      qrCodeImg: "images",
      qrCodeId: "QA0001",
      roomNo: "123",
      roomType: "Deluxe Room",
      noOfScanByGuest: "35",
      status: "Inactive",
      issueDataAndTime: "20-01-2024 | 11:35am",
      cancelDataAndTime: "20-01-2024 | 11:35am",
    },
    {
      qrCodeImg: "images",
      qrCodeId: "QA0001",
      roomNo: "123",
      roomType: "Deluxe Room",
      noOfScanByGuest: "35",
      status: "Inactive",
      issueDataAndTime: "20-01-2024 | 11:35am",
      cancelDataAndTime: "20-01-2024 | 11:35am",
    },
    {
      qrCodeImg: "images",
      qrCodeId: "QA0001",
      roomNo: "123",
      roomType: "Deluxe Room",
      noOfScanByGuest: "35",
      status: "Inactive",
      issueDataAndTime: "20-01-2024 | 11:35am",
      cancelDataAndTime: "20-01-2024 | 11:35am",
    },
    {
      qrCodeImg: "images",
      qrCodeId: "QA0001",
      roomNo: "123",
      roomType: "Deluxe Room",
      noOfScanByGuest: "35",
      status: "Inactive",
      issueDataAndTime: "20-01-2024 | 11:35am",
      cancelDataAndTime: "20-01-2024 | 11:35am",
    },
    {
      qrCodeImg: "images",
      qrCodeId: "QA0001",
      roomNo: "123",
      roomType: "Deluxe Room",
      noOfScanByGuest: "35",
      status: "Inactive",
      issueDataAndTime: "20-01-2024 | 11:35am",
      cancelDataAndTime: "20-01-2024 | 11:35am",
    },
    {
      qrCodeImg: "images",
      qrCodeId: "QA0001",
      roomNo: "123",
      roomType: "Deluxe Room",
      noOfScanByGuest: "35",
      status: "Inactive",
      issueDataAndTime: "20-01-2024 | 11:35am",
      cancelDataAndTime: "20-01-2024 | 11:35am",
    },
    {
      qrCodeImg: "images",
      qrCodeId: "QA0001",
      roomNo: "123",
      roomType: "Deluxe Room",
      noOfScanByGuest: "35",
      status: "Inactive",
      issueDataAndTime: "20-01-2024 | 11:35am",
      cancelDataAndTime: "20-01-2024 | 11:35am",
    },
  ];

  const columnDefs = [
    {
      headerName: "Qr Code",
      field: "qrCodeImg",
      filter: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      cellRenderer: qrCodeImage,
    },
    {
      headerName: "Qr Code Id",
      field: "qrCodeId",
      filter: true,
    },
    {
      headerName: "Room No",
      field: "roomNo",
      filter: true,
    },
    {
      headerName: "Room Type",
      field: "roomType",
      filter: true,
    },
    {
      headerName: "No. Of Scan By Guest",
      field: "noOfScanByGuest",
    },
    {
      headerName: "Status",
      field: "status",
    },
    {
      headerName: "Issue Date & Time",
      field: "issueDataAndTime",
    },
    {
      headerName: "Cancel Date & Time",
      field: "cancelDataAndTime",
    },
  ];
  return (
    <div className="">
      <div className="flex items-center justify-end gap-4">
        <SubmitButton text={"Today"} onClick={() => {}} />
        <div className="relative w-[308px] h-10 flex flex-row justify-center items-center gap-3 border border-[#E4E4E7] rounded-md box-border bg-white text-[#18181B] p-3">
          <input
            value={date}
            onChange={(e) => setDate(e.target.value)}
            type={"date"}
            className="flex w-1/2 my-[10px] bg-white items-center border-none shadow-none rounded h-5 font-normal cursor-pointer select-none text-[14px] leading-[18px] tracking-[-0.8px]"
          />
          <input
            value={date}
            onChange={(e) => setDate(e.target.value)}
            type={"date"}
            className="flex w-1/2 my-[10px] bg-white items-center border-none shadow-none rounded h-5 font-normal cursor-pointer select-none text-[14px] leading-[18px] tracking-[-0.8px]"
          />
        </div>
      </div>
      <div className="mt-4">
        <CustomeTable
          tableName={"Qr Code History Table List"}
          rowData={tableData}
          columnDefs={columnDefs}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </div>
  );
};

export default QrCodeHistory;
