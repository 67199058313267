import React from "react";
import FoodTopButton from "./FoodTopButton";
import { FaArrowRight } from "react-icons/fa6";
import { TbLayoutGrid } from "react-icons/tb";
import { PiCookingPot, PiHamburger } from "react-icons/pi";
import { CiFries } from "react-icons/ci";
import { IoFastFoodOutline } from "react-icons/io5";
import { GiFullPizza } from "react-icons/gi";

const FoodHeader = ({ params }) => {
  const routes = [
    {
      name: "All Cuisine",
      index: 1,
      icon: <TbLayoutGrid />,
    },
    {
      name: "American Cuisine",
      index: 2,
      icon: <PiHamburger />,
    },
    {
      name: "Thai Cuisine",
      index: 3,
      icon: <PiCookingPot />,
    },
    {
      name: "French Cuisine",
      index: 4,
      icon: <CiFries />,
    },
    {
      name: "Indian Cuisine",
      index: 5,
      icon: <IoFastFoodOutline />,
    },
    {
      name: "Italian Cuisine",
      index: 6,
      icon: <GiFullPizza />,
    },
  ];
  return (
    <div className="relative max-w-full">
      <button
        type="button"
        className="size-[47px] bg-base-primary hover:bg-base-primary_hover text-white rounded-full flex items-center justify-center duration-300 ease-[0.3s] absolute top-9 -right-6 z-[10000] py-[2px]"
      >
        <FaArrowRight size={24} />
      </button>
      <div className="flex justify-between gap-2 w-full items-center overflow-x-auto overflow-y-hidden">
        {routes?.map((route) => {
          return (
            <FoodTopButton
              key={route?.index}
              name={route?.name}
              index={route?.index}
              params={params}
              Icon={route?.icon}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FoodHeader;
