/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const RoomTopBookingSource = () => {
  const [datas, setDatas] = useState([]);

  const { sales } = useSelector((state) => state.SalesReport);
  useEffect(() => {
    if (sales && Array.isArray(sales) && sales?.length) {
      let expense = sales?.map((d) => {
        return {
          sourceName: d?.sourceName,
          bookingNo: d?.bookingNo,
        };
      });
      setDatas(expense);
    }
  }, [sales]);

  const tableData = [
    {
      sourceName: "Air bnb",
      bookingNo: "987",
    },
    {
      sourceName: "Funotel",
      bookingNo: "987",
    },
    {
      sourceName: "Booking.com",
      bookingNo: "987",
    },
    {
      sourceName: "Hotka",
      bookingNo: "987",
    },
    {
      sourceName: "Gozayan",
      bookingNo: "987",
    },
    {
      sourceName: "Trip Plus",
      bookingNo: "987",
    },
    {
      sourceName: "Booking.com",
      bookingNo: "987",
    },
    {
      sourceName: "Air bnb",
      bookingNo: "987",
    },
    {
      sourceName: "Funotel",
      bookingNo: "987",
    },
    {
      sourceName: "Booking.com",
      bookingNo: "987",
    },
    {
      sourceName: "Hotka",
      bookingNo: "987",
    },
    {
      sourceName: "Gozayan",
      bookingNo: "987",
    },
    {
      sourceName: "Trip Plus",
      bookingNo: "987",
    },
    {
      sourceName: "Booking.com",
      bookingNo: "987",
    },
    {
      sourceName: "Air bnb",
      bookingNo: "987",
    },
    {
      sourceName: "Funotel",
      bookingNo: "987",
    },
    {
      sourceName: "Booking.com",
      bookingNo: "987",
    },
    {
      sourceName: "Hotka",
      bookingNo: "987",
    },
    {
      sourceName: "Gozayan",
      bookingNo: "987",
    },
    {
      sourceName: "Trip Plus",
      bookingNo: "987",
    },
    {
      sourceName: "Booking.com",
      bookingNo: "987",
    },
    {
      sourceName: "Air bnb",
      bookingNo: "987",
    },
    {
      sourceName: "Funotel",
      bookingNo: "987",
    },
    {
      sourceName: "Booking.com",
      bookingNo: "987",
    },
    {
      sourceName: "Hotka",
      bookingNo: "987",
    },
    {
      sourceName: "Gozayan",
      bookingNo: "987",
    },
    {
      sourceName: "Trip Plus",
      bookingNo: "987",
    },
    {
      sourceName: "Booking.com",
      bookingNo: "987",
    },
    {
      sourceName: "Air bnb",
      bookingNo: "987",
    },
    {
      sourceName: "Funotel",
      bookingNo: "987",
    },
    {
      sourceName: "Booking.com",
      bookingNo: "987",
    },
    {
      sourceName: "Hotka",
      bookingNo: "987",
    },
    {
      sourceName: "Gozayan",
      bookingNo: "987",
    },
    {
      sourceName: "Trip Plus",
      bookingNo: "987",
    },
    {
      sourceName: "Booking.com",
      bookingNo: "987",
    },
  ];

  return (
    <div>
      <div className="border-b border-[#E5E9EB] h-[80px]">
        <div className="text-[18px] leading-[22.68px] tracking-[-0.8px] text-[#000000] font-medium mx-5 mt-4">
          Top Booking Source
        </div>
        <div className="flex items-center justify-between px-5 text-[14px] leading-6 font-normal text-[#6E7C87] tracking-[-0.6%] mt-5">
          <div className="">Source Name</div>
          <div className="">Booking No</div>
        </div>
      </div>
      <div className="overflow-y-auto h-[360px] w-full scroll-bar3">
        <ul className="h-[360px] w-full px-2">
          {tableData.map((data) => {
            return (
              <li
                className="flex items-center justify-between border-t  py-4 p-3"
                key={data.bookingNo}
              >
                {data.sourceName}
                <div className="">{data.bookingNo}</div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default RoomTopBookingSource;
