import React, { useState } from "react";
import CustomModal from "../../../components/modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../../components/form/InputField";
import { RxCross2 } from "react-icons/rx";
import AddButton from "../../../components/buttons/AddButton";
import AddAmenity from "../../../components/modals/AddAmenity";
import { ROOM_AMENITY } from "../../../constants/room-amenities";
import { updateRoomAmenityAction } from "../../../lib/slices/room/roomAmenitiesSlice";
import { notifyError, notifySuccess } from "../../../components/common/alert";
import FormSubmitButton from "../../../components/buttons";

const EditAmenity = ({ close, editAmenityData }) => {
  const dispatch = useDispatch();
  const { hotel } = useSelector((state) => state.hotel);
  const { error, loading } = useSelector((state) => state.roomAmenity);
  const [addAmenity, setAddAmenity] = useState("");
  const [isAddAmenity, setIsAddAmenity] = useState(false);
  const [addAmenityToList, setAddAmenityToList] = useState(ROOM_AMENITY);

  const [updateData, setUpdateData] = useState({
    listName: editAmenityData?.listName || "",
    amenities: editAmenityData?.amenities || [],
  });

  // Handle form submission
  const handleSubmit = () => {
    const updateAmenityData = {
      amenityId: editAmenityData?._id,
      hotelId: hotel?._id,
      listName: updateData.listName,
      amenities: updateData?.amenities?.map((item) => ({
        amenity: item,
      })),
    };

    if (
      !updateData?.listName ||
      !Array.isArray(editAmenityData?.amenities) ||
      editAmenityData.amenities.length === 0
    ) {
      notifyError(error || "Please provide a valid list name and amenities.");
      return;
    }
    dispatch(updateRoomAmenityAction(updateAmenityData));
    if (!loading) {
      notifySuccess(error || "Amenity updated successfully.");
      close();
    }
  };

  // Add a new amenity to the list
  const handleAddAmenity = () => {
    if (addAmenity.trim()) {
      setAddAmenityToList((prev) => [...prev, addAmenity.trim()]);
      setAddAmenity("");
      setIsAddAmenity(false);
    }
  };

  // Select an amenity to add to the updateData
  const handleSelectAmenity = (item) => {
    setUpdateData((prev) => ({
      ...prev,
      amenities: prev.amenities.includes(item)
        ? prev.amenities
        : [...prev.amenities, item],
    }));
  };

  // Remove an amenity from the updateData
  const handleRemoveAmenity = (item) => {
    setUpdateData((prev) => ({
      ...prev,
      amenities: prev.amenities.filter((amenity) => amenity !== item),
    }));
  };

  return (
    <CustomModal close={close} heading="Edit Room Amenity">
      <div className="w-[50vw] h-[58vh]">
        <div className="mt-4">
          <InputField
            label={"Room Amenity List Name"}
            value={updateData.listName}
            onChange={(e) =>
              setUpdateData({ ...updateData, listName: e.target.value })
            }
          />
        </div>
        <div className="grid grid-cols-2 gap-16 text-base text-[#000000] capitalize mt-4">
          <div>
            <div className="h-14 flex items-center justify-between">
              <label>Available Amenities</label>
              <AddButton
                className={"h-10"}
                text={"Add New Amenties"}
                onClick={() => setIsAddAmenity(true)}
              />
            </div>
            <ul className="h-[36.5vh] overflow-y-auto overflow-x-hidden dropdown-scroll flex flex-wrap gap-4 border border-[#D0D5DD] pl-4 py-4 pr-2 rounded-[10px]">
              {addAmenityToList.map((item, id) => (
                <li key={id}>
                  <button
                    type="button"
                    onClick={() => handleSelectAmenity(item)}
                    className={`cursor-pointer select-none h-10 flex items-center gap-3 border border-[#D0D5DD] rounded-lg px-4 ${
                      updateData.amenities.includes(item)
                        ? "bg-[#00a35e33]"
                        : ""
                    }`}
                  >
                    {item.replace("_", " ")}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <label htmlFor="" className="h-14 flex items-end">
              Selected Room Amenities
            </label>
            <ul className="h-[36.5vh] overflow-y-auto overflow-x-hidden dropdown-scroll flex flex-wrap gap-4  border border-[#D0D5DD] pl-4 py-4 pr-2 rounded-[10px]">
              {updateData?.amenities.map((value) => {
                return (
                  <li
                    key={value}
                    className="flex items-center justify-between h-10 w-fit px-4 py-2 border border-base-primary rounded-lg bg-[#00a35e33]"
                  >
                    <div>{value.replace("_", " ")}</div>
                    <button
                      type="button"
                      onClick={() => handleRemoveAmenity(value)}
                      className="ml-2"
                    >
                      <RxCross2
                        size={18}
                        className="transition-all ease-in-out duration-300 scale-90 hover:scale-110 hover:text-[#F04438]"
                      />
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        {/* Add New Amenity Modal */}
        {isAddAmenity && (
          <AddAmenity
            close={() => setIsAddAmenity(false)}
            value={addAmenity}
            onChange={(e) => setAddAmenity(e.target.value)}
            onClick={handleAddAmenity}
          />
        )}

        {/* Submit Button */}
        <div className="flex items-center justify-end gap-4 mt-6">
          <FormSubmitButton
            buttonText={"Update"}
            onSubmit={handleSubmit}
            loading={loading}
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default EditAmenity;
