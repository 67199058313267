import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReportsCard from "./ReportsCard";
import CustomeTable from "../../components/widget/CustomeTable";
import { LuArrowDownToLine, LuArrowUpToLine } from "react-icons/lu";
import { LiaWaveSquareSolid } from "react-icons/lia";
import { GoListUnordered } from "react-icons/go";

const EmployeeSalaryReports = (props) => {
  // const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const { sales } = useSelector((state) => state.SalesReport);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    props.getAssetsFromToDateAll(
      props?.hotel?._id,
      props?.fromDate,
      props?.toDate
    );
  }, [props?.fromDate, props?.toDate]);

  useEffect(() => {
    if (sales && Array.isArray(sales) && sales?.length) {
      let expense = sales?.map((d) => {
        return {
          employeeID: d?.employeeID,
          employeeName: d?.employeeName,
          designation: d?.designation,
          department: d?.department,
          salary: d?.salary,
          // type: d?.type,
          // _id: d?._id,
        };
      });
      setDatas(expense);
    }
  }, [sales]);

  const tableData = [
    {
      employeeID: "EX001",
      employeeName: "John Doe",
      designation: "Room Inspector",
      department: "House Keeping",
      salary: "$500",
    },
    {
      employeeID: "EX001",
      employeeName: "John Doe",
      designation: "Room Inspector",
      department: "House Keeping",
      salary: "$500",
    },
    {
      employeeID: "EX001",
      employeeName: "John Doe",
      designation: "Room Inspector",
      department: "House Keeping",
      salary: "$500",
    },
    {
      employeeID: "EX001",
      employeeName: "John Doe",
      designation: "Room Inspector",
      department: "House Keeping",
      salary: "$500",
    },
    {
      employeeID: "EX001",
      employeeName: "John Doe",
      designation: "Room Inspector",
      department: "House Keeping",
      salary: "$500",
    },
  ];
  const columnDefs = [
    {
      headerName: "Employee ID",
      field: "employeeID",
      minWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Employee Name",
      field: "employeeName",
      filter: true,
    },
    {
      headerName: "Designation",
      field: "designation",
      filter: true,
    },
    {
      headerName: "Department",
      field: "department",
      filter: true,
    },
    {
      headerName: "Salary",
      field: "salary",
      filter: true,
    },
  ];
  return (
    <div className="page-container">
      <div className="grid grid-cols-4 items-center gap-4 my-4">
        <ReportsCard
          icon={<GoListUnordered />}
          className={"bg-[#F9F5FF]"}
          heading={"Total Salary"}
          value={"1258"}
        />
        <ReportsCard
          icon={<LuArrowUpToLine />}
          className={"bg-[#F1FADF]"}
          heading={"Highest Paid Employee"}
          value={"800"}
        />
        <ReportsCard
          icon={<LuArrowDownToLine />}
          className={"bg-[#ECFDF3]"}
          heading={"Lowest Paid Employee"}
          value={"425"}
        />
        <ReportsCard
          icon={<LiaWaveSquareSolid />}
          className={"bg-[#E8EBFF]"}
          heading={"Average Salary"}
          value={"425"}
        />
      </div>
      {/* {datas && datas?.length ? ( */}
      <CustomeTable
        tableName={"Employee Salary Report table"}
        columnDefs={columnDefs}
        rowData={tableData}
        setSelectedRows={setSelectedRows}
      />
      {/* ) : null} */}
    </div>
  );
};
export default EmployeeSalaryReports;
