import React, { useState } from "react";
import  CustomeTable from "../../components/widget/CustomeTable";
import  DeleteButton from "../../components/buttons/DeleteButton";
import  EditButton from "../../components/buttons/EditButton";
import  OutlineButton from "../../components/buttons/OutlineButton";

const FoodBuffetPrice = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  // const [selectItem, setSelectItem] = useState([]);
  const [price, setPrice] = useState("$29.00");
  // const [tax, setTax] = useState("$10.00");
  // const [totalPrice, setTotalPrice] = useState("$39.00");
  // console.log("selected value : ", selectItem);

  // const TYPE_PER_PERSON = [
  //   { name: "Per Persion" },
  //   { name: "Per 2 Persion" },
  //   { name: "Per 5 Persion" },
  //   { name: "Per 10 Persion" },
  //   { name: "Per 20 Persion" },
  //   { name: "Per 50 Persion" },
  // ];
  const rowData = [
    {
      meal: "Breakfast",
      priceType: "Price Per Room",
      price: "$29.00",
      addTax: "",
      foodTax: "$10.00",
      salesTax: "$10.00",
      totalPrice: "$39.00",
    },
    {
      meal: "Lunch",
      priceType: "Price Per Room",
      price: "$29.00",
      addTax: "",
      foodTax: "$10.00",
      salesTax: "$10.00",
      totalPrice: "$39.00",
    },
    {
      meal: "Hi-Tea",
      priceType: "Price Per Room",
      price: "$29.00",
      addTax: "",
      foodTax: "$10.00",
      salesTax: "$10.00",
      totalPrice: "$39.00",
    },
    {
      meal: "Dinner",
      priceType: "Price Per Room",
      price: "$29.00",
      addTax: "",
      foodTax: "$10.00",
      salesTax: "$10.00",
      totalPrice: "$39.00",
    },
  ];

  const handlePricePerItem = () => {
    return (
      <div className="flex items-center justify-center h-full">
        <input
          type="text"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          className="h-[30px] rounded-md text-[12] leading-6 font-medium text-[#667085]"
        />
      </div>
    );
  };

  const handleAddTax = () => {
    return (
      <div className="flex items-center justify-center h-full">
        <OutlineButton text={"Add Tax"} sizing={"h-[30px]"} />
      </div>
    );
  };

  const handleActions = ({ value }) => {
    return (
      <div className="h-full flex items-center gap-4">
        <EditButton className="h-8" />
        <DeleteButton className="h-8"/>
      </div>
    );
  };
  const columnDefs = [
    {
      headerName: "Meal",
      field: "meal",
      maxWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "PriceType",
      field: "priceType",
      filter: true,
    },
    {
      headerName: "Price",
      field: "price",
      filter: true,
      cellRenderer: handlePricePerItem,
    },

    {
      headerName: "Add Tax",
      field: "addTax",
      filter: true,
      cellRenderer: handleAddTax,
    },
    {
      headerName: "Food Tax",
      field: "foodTax",
      filter: true,
    },
    {
      headerName: "Sales Tax",
      field: "salesTax",
      filter: true,
    },
    {
      headerName: "Total Price",
      field: "totalPrice",
      filter: true,
    },
    {
      headerName: "Actions",
      field: "id",
      minWidth: 200,
      cellRenderer: handleActions,
    },
  ];

  return (
    <CustomeTable
      tableName={"Food Buffet Price"}
      rowData={rowData}
      setSelectedRows={setSelectedRows}
      columnDefs={columnDefs}
    />
  );
};

export default FoodBuffetPrice;
