import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import ReportsCard from "./ReportsCard";
import CustomeTable from "../../components/widget/CustomeTable";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import { MdOutlineSegment } from "react-icons/md";
import { LuArrowDownToLine, LuArrowUpToLine } from "react-icons/lu";

const InventoryReports = (props) => {
  // const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const { sales } = useSelector((state) => state.SalesReport);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    props.getAssetsFromToDateAll(
      props?.hotel?._id,
      props?.fromDate,
      props?.toDate
    );
  }, [props?.fromDate, props?.toDate]);

  useEffect(() => {
    if (sales && Array.isArray(sales) && sales?.length) {
      let expense = sales?.map((d) => {
        return {
          item: d?.item,
          totalQuantity: d?.totalQuantity,
          availableQuantity: d?.availableQuantity,
          costPerUnit: d?.costPerUnit,
          totalValue: d?.totalValue,
        };
      });
      setDatas(expense);
    }
  }, [sales]);
  const tableData = [
    {
      id: "IN001",
      item: "Towel",
      totalQuantity: "500",
      availableQuantity: "400",
      costPerUnit: "$10",
      totalValue: "$500",
    },
    {
      id: "IN002",
      item: "Pillow",
      totalQuantity: "500",
      availableQuantity: "400",
      costPerUnit: "$10",
      totalValue: "$500",
    },
    {
      id: "IN003",
      item: "Towel",
      totalQuantity: "500",
      availableQuantity: "400",
      costPerUnit: "$10",
      totalValue: "$500",
    },
    {
      id: "IN004",
      item: "Towel",
      totalQuantity: "500",
      availableQuantity: "400",
      costPerUnit: "$10",
      totalValue: "$500",
    },
    {
      id: "IN005",
      item: "Pillow",
      totalQuantity: "500",
      availableQuantity: "400",
      costPerUnit: "$10",
      totalValue: "$500",
    },
  ];

  const columnDefs = [
    {
      headerName: "Inventory ID",
      field: "id",
      minWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Item",
      field: "item",
      filter: true,
    },
    {
      headerName: "Total Quantity",
      field: "totalQuantity",
      filter: true,
    },
    {
      headerName: "Available Quantity",
      field: "availableQuantity",
      filter: true,
    },
    {
      headerName: "Cost/Unit",
      field: "costPerUnit",
      filter: true,
    },
    {
      headerName: "Total Value",
      field: "totalValue",
      filter: true,
    },
  ];

  return (
    <div className="page-container">
      <div className="grid grid-cols-4 items-center gap-4 my-4">
        <ReportsCard
          icon={<MdOutlineSegment />}
          className={"bg-[#F1FADF]"}
          heading={"Total item"}
          value={"1258"}
        />
        <ReportsCard
          icon={<LuArrowUpToLine />}
          className={"bg-[#E8EBFF]"}
          heading={"High Value Item"}
          value={"800"}
        />
        <ReportsCard
          icon={<LuArrowDownToLine />}
          className={"bg-[#FCEEDD]"}
          heading={"Low Value Item"}
          value={"425"}
        />
        <ReportsCard
          icon={<HiOutlineCurrencyDollar />}
          className={"bg-[#FCEAEA]"}
          heading={"Total Value"}
          value={"425"}
        />
      </div>

      {/* {datas && datas?.length ? ( */}
      <CustomeTable
        tableName={"Inventory Report table"}
        columnDefs={columnDefs}
        rowData={tableData}
        setSelectedRows={setSelectedRows}
      />
      {/* ) : null} */}
    </div>
  );
};

export default InventoryReports;
