import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import authAxios from "../../services/authAxios";

//  get all room Action
export const getAllRoomAction = createAsyncThunk(
  "GET_ALL_ROOMS_ACTION",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId } = credentials;
      if (!hotelId) return;
      const url = `/api/hotel/owner/rooms/all?hotelId=${hotelId}`;
      const response = await authAxios.get(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// add room action
export const addRoomAction = createAsyncThunk(
  "ADD_ROOM_ACTION",
  async (credentials, thunkAPI) => {
    try {
      const url = `/api/hotel/owner/rooms/add`;
      const response = await axios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// update room action
export const updateRoomAction = createAsyncThunk(
  "UPDATE_ROOM_ACTION",
  async (credentials, thunkAPI) => {
    try {
      const url = `/api/hotel/owner/rooms/update`;
      const response = await axios.put(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// delete Room action
export const deleteRoomAction = createAsyncThunk(
  "DELETE_ROOM_ACTION",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId, roomId } = credentials;
      let url = `api/hotel/owner/rooms`;
      const response = await authAxios.delete(url, {
        data: { hotelId, roomId },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Get room by roomId & by roomType
export const getRoomByIdAndRoomTypeAction = createAsyncThunk(
  "GET_ROOMS_BY_ID_AND_ROOM_TYPE_ACTION",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId, roomId, roomType } = credentials;
      if (!hotelId && !roomId && !roomId) return;
      const url = `api/hotel/owner/rooms/room?hotelId=${hotelId}&roomId=${roomId}&roomType=${roomType}`;
      const response = await authAxios.get(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Helper function for handling pending state
const handlePending = (state) => {
  state.loading = true;
  state.error = null;
};
// Helper function for handling fulfilled state
const handleFulfilled = (state, action) => {
  state.loading = false;
  state.rooms = action.payload;
};
// Helper function for handling rejected state
const handleRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload;
};

// all rooms slice
export const addRoomSlice = createSlice({
  name: "HOTEL_ROOMS_SLICE",
  initialState: {
    loading: false,
    rooms: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addRoomAction.pending, handlePending)
      .addCase(addRoomAction.fulfilled, handleFulfilled)
      .addCase(addRoomAction.rejected, handleRejected)
      .addCase(updateRoomAction.pending, handlePending)
      .addCase(updateRoomAction.fulfilled, handleFulfilled)
      .addCase(updateRoomAction.rejected, handleRejected)
      .addCase(getAllRoomAction.pending, handlePending)
      .addCase(getAllRoomAction.fulfilled, handleFulfilled)
      .addCase(getAllRoomAction.rejected, handleRejected)
      .addCase(getRoomByIdAndRoomTypeAction.pending, handlePending)
      .addCase(getRoomByIdAndRoomTypeAction.fulfilled, handleFulfilled)
      .addCase(getRoomByIdAndRoomTypeAction.rejected, handleRejected)
      .addCase(deleteRoomAction.pending, handlePending)
      .addCase(deleteRoomAction.fulfilled, handleFulfilled)
      .addCase(deleteRoomAction.rejected, handleRejected);
  },
});
