import React, { useEffect, useState } from "react";
import CloseButton from "../../../components/buttons/CloseButton";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import ShiftButton from "../../../components/buttons/ShiftButton";
import SelectBox from "../../../components/form/SelectBox";
import Inputfield from "../../../components/form/InputField";
import { useDispatch, useSelector } from "react-redux";
import { getAllMinibarItemsAction } from "../../../lib/slices/room/roomMinibarItemSlice";
import AddItem from "./AddItem";
import { toCapitalize } from "../../../middlewares/toCapitalize";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../components/common/alert";
import { addMinibarAction } from "../../../lib/slices/room/roomMinibarSlice";
import FormSubmitButton from "../../../components/buttons";

const CreateMinibarList = ({ close }) => {
  const dispatch = useDispatch();
  const { hotel } = useSelector((state) => state.hotel);
  const { minibarItem, loading, error } = useSelector(
    (state) => state.minibarItem
  );
  const [isOpenAddItem, setIsOpenAddItem] = useState(false);
  // left side table
  const [availableItems, setAvailabelItems] = useState([]);
  const [selectedAvailableItemRows, setSelectedAvailableItemRows] = useState(
    []
  );
  const [selectedItemsData, setSelectedItemsData] = useState([]);
  // right side table
  const [selectedItemRows, setSelectedItemRows] = useState([]);

  const [formData, setFormData] = useState({
    minibarConfigName: "",
  });

  // handle change
  const handleChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  // get Minibar Items
  useEffect(() => {
   if(hotel?._id){
    dispatch(getAllMinibarItemsAction({ hotelId: hotel?._id }));
   }
  }, [hotel]);

  useEffect(() => {
    if (minibarItem && Array.isArray(minibarItem)) {
      const data = minibarItem?.map((minibarData) => {
        const { _id, itemName, itemType, dietaryPreference, quantity, price } =
          minibarData;
        return {
          itemId: _id,
          foodAndBeveragesName: toCapitalize(itemName),
          fAndBType: toCapitalize(itemType),
          dietaryPreference: toCapitalize(dietaryPreference),
          quantity: toCapitalize(quantity?.netQuantity),
          measurementUnit: toCapitalize(quantity?.measurementUnit),
          price: price?.netPrice,
        };
      });
      if (data?.length) {
        setAvailabelItems(data?.reverse());
      }
    }
  }, [minibarItem]);

  // add to list
  const handleItemAddToList = () => {
    const addItemToListData = selectedAvailableItemRows?.map((data) => ({
      itemId: data?.itemId || "",
      foodAndBeveragesName: data?.foodAndBeveragesName || "",
      price: data?.price || "",
      quantity: data?.quantity || "",
      measurementUnit: data?.measurementUnit || "",
    }));
    setSelectedItemsData(addItemToListData);
  };

  const handleItemRemoveToList = () => {
    const selectedId = selectedItemRows?.map((data) => data?.itemId);
    const filteredItems = selectedItemsData?.filter(
      (data) => !selectedId?.includes(data?.itemId)
    );
    setSelectedItemsData(filteredItems);
  };
  // submit data
  const handleSubmit = () => {
    const itemsData = selectedItemRows?.map((data) => {
      return {
        itemId: data.itemId,
        itemName: data?.foodAndBeveragesName || "item name optional",
        quantity: {
          netQuantity: data?.quantity,
          measurementUnit: data?.measurementUnit,
        },
      };
    });
    const minibarData = {
      hotelId: hotel?._id,
      minibar: formData?.minibarConfigName,
      items: itemsData,
    };
    if (!formData?.minibarConfigName || !selectedItemsData) {
      notifyWarning(
        "Please Enter Minibar Confiquration List Name and select item."
      );
      return;
    }

    dispatch(addMinibarAction(minibarData));

    if (!loading) {
      notifySuccess(error || "minibar added Successfully.");
      setFormData({
        minibarConfigName: "",
      });
      close();
    } else {
      notifyError(error || "Please Enter valid details.");
    }
  };

  const availableItemsColumnDefs = [
    {
      headerName: "Product",
      field: "foodAndBeveragesName",
      checkboxSelection: true,
      minWidth: 150,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Price",
      field: "price",
      filter: true,
    },
    {
      headerName: "Quantity",
      field: "quantity",
      filter: true,
    },
    {
      headerName: "Quantity Measure",
      field: "measurementUnit",
      filter: true,
    },
  ];

  const seletedItemColumnDefs = [
    {
      headerName: "Product",
      field: "foodAndBeveragesName",
      checkboxSelection: true,
      minWidth: 150,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Price",
      field: "price",
      filter: true,
    },
    {
      headerName: "Quantity",
      field: "quantity",
      filter: true,
    },
    {
      headerName: "Quantity Measure",
      field: "measurementUnit",
      filter: true,
    },
  ];
  return (
    <div className="w-full bg-white border border-[#D0D5DD] rounded-[10px] mt-4 p-6">
      <div className="flex items-center justify-between">
        <div className="text-[26px] leading-[32.76px] font-bold text-[#07090D]">
          Configuration of Minibar By Room Type
        </div>
        <CloseButton onClick={close} />
      </div>
      <div className="w-2/5 mt-6">
        <Inputfield
          label={"Minibar Configuration Name"}
          value={formData.minibarConfigName}
          onChange={(e) => handleChange("minibarConfigName", e.target.value)}
          placeholder={"Enter Minibar Configuration Name"}
        />
      </div>
      <div className="grid grid-cols-10 w-full mt-6">
        {/* Left side */}

        <div className="col-span-4">
          <SelectBox
            label={"Available Items"}
            heading={"Select items to add"}
            btnName={"Add item"}
            handleTopBtn={() => setIsOpenAddItem(true)}
            rowData={availableItems}
            columnDefs={availableItemsColumnDefs}
            setSelectedRows={setSelectedAvailableItemRows}
          />
        </div>
        {isOpenAddItem ? (
          <AddItem close={() => setIsOpenAddItem(false)} />
        ) : null}
        {/* Buttons for add and remove */}
        <div className="col-span-2 flex flex-col items-center justify-center gap-6">
          <ShiftButton
            name={"Add"}
            icon={<AiOutlineDoubleRight size={18} />}
            onClick={handleItemAddToList}
          />

          <ShiftButton
            name={"Remove"}
            icon={<AiOutlineDoubleLeft size={18} />}
            onClick={handleItemRemoveToList}
          />
        </div>

        {/* Right side */}
        <div className="col-span-4 w-full">
          <SelectBox
            label={"selected Item"}
            heading={"Add items to List"}
            showBtn={false}
            rowData={selectedItemsData}
            columnDefs={seletedItemColumnDefs}
            setSelectedRows={setSelectedItemRows}
          />
        </div>
      </div>
      <div className="flex items-center justify-end mt-6">
        <FormSubmitButton
          buttonText={"Configure Minibar"}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export default CreateMinibarList;
