import React, { useEffect, useState } from "react";
import AddButton from "../../../components/buttons/AddButton";
import CustomeTable from "../../../components/widget/CustomeTable";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMinibarItemAction,
  getAllMinibarItemsAction,
} from "../../../lib/slices/room/roomMinibarItemSlice";
import { toCapitalize } from "../../../middlewares/toCapitalize";
import AddMinibarItems from "./AddMinibarItems";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../components/common/alert";
import EditMinibarItem from "./EditMinibarItem";

const AllMinibarItems = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [isMinibarItems, setIsMinibarItems] = useState(false);
  const [isEditItem, setIsEditItem] = useState(false);
  const [datas, setDatas] = useState([]);

  const dispatch = useDispatch();
  const { hotel } = useSelector((state) => state.hotel);
  const { minibarItem, loading, error } = useSelector(
    (state) => state.minibarItem
  );
  const [getMinibarItem, setGetMinibarItem] = useState(null);

  useEffect(() => {
    if (hotel?._id) {
      dispatch(getAllMinibarItemsAction({ hotelId: hotel?._id }));
    }
  }, []);

  useEffect(() => {
    if (minibarItem && Array.isArray(minibarItem)) {
      const data = minibarItem?.map((minibarData) => {
        const { _id, itemName, itemType, dietaryPreference, quantity, price } =
          minibarData;
        return {
          itemId: _id,
          foodAndBeveragesName: toCapitalize(itemName),
          fAndBType: toCapitalize(itemType),
          dietaryPreference: toCapitalize(dietaryPreference),
          quantity: toCapitalize(quantity?.netQuantity),
          quantityMeasure: toCapitalize(quantity?.measurementUnit),
          price: price?.netPrice,
          // + " " + toCapitalize(price?.currency),
        };
      });
      if (data?.length) {
        setDatas(data?.reverse());
      }
    }
  }, [minibarItem]);

  const handleEdit = () => {
    if (!selectedRows.length) {
      notifyWarning("Please selecte at once in row to be edit.");
      return;
    }
    setIsEditItem(true);
    setGetMinibarItem(selectedRows[0]);
  };

  const handleDelete = () => {
    const itemId = selectedRows.map((minibarItem) => minibarItem.itemId);
    if (selectedRows.length === 0) {
      notifyWarning("Please select at least one row to delete.");
      return;
    }

    if (!itemId || selectedRows.length === 0) return;
    dispatch(deleteMinibarItemAction({ hotelId: hotel?._id, itemId }));

    if (!loading) {
      notifySuccess(error || "Deleted Minibar item successfully.");
    } else {
      notifyError(error || "an error occured");
    }
  };

  const columnDefs = [
    {
      headerName: "Item Id",
      field: "itemId",
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Food & Baverages Name",
      field: "foodAndBeveragesName",
      filter: true,
    },
    {
      headerName: "F&B Type",
      field: "fAndBType",
      filter: true,
    },
    {
      headerName: "Dietary Preferences",
      field: "dietaryPreference",
      filter: true,
    },
    {
      headerName: "Quantity",
      field: "quantity",
      filter: true,
    },
    {
      headerName: "Quantity Measure ",
      field: "quantityMeasure",
      filter: true,
    },
    {
      headerName: "Price To Customer",
      field: "price",
      filter: true,
    },
  ];

  return (
    <div>
      <div className="flex items-center justify-end">
        <AddButton
          text={"Minibar Items"}
          onClick={() => setIsMinibarItems(true)}
        />
      </div>
      {isMinibarItems ? (
        <AddMinibarItems close={() => setIsMinibarItems(false)} />
      ) : null}
      {isEditItem ? (
        <EditMinibarItem
          close={() => setIsEditItem(false)}
          editItem={getMinibarItem}
        />
      ) : null}
      <div className="mt-4">
        <CustomeTable
          size="h-[55vh]"
          tableName={"Minibar Item List Table"}
          rowData={datas}
          setSelectedRows={setSelectedRows}
          columnDefs={columnDefs}
          editFunc={handleEdit}
          deleteFunc={handleDelete}
        />
      </div>
    </div>
  );
};

export default AllMinibarItems;
