import React from "react";
import DownloadButton from "../../components/buttons/DownloadButton";
import { RiDownload2Line } from "react-icons/ri";
import CancelButton from "../../components/buttons/CancelButton";

const SelectTemplate = ({ close }) => {
  const templateData = [
    {
      id: 1,
      name: "Template 1",
      imgLink: `https://i.pinimg.com/474x/7f/51/86/7f5186328a8a4e4567fffc66a591c1b2.jpg`,
    },
    {
      id: 2,
      name: "Template 2",
      imgLink: `https://d3jmn01ri1fzgl.cloudfront.net/photoadking/webp_thumbnail/whatsapp-qr-code-flyer-template-b6131ka372c206.webp`,
    },
    {
      id: 3,
      name: "Template 3",
      imgLink: `https://img.freepik.com/premium-psd/qr-code-standee-design-function-03_915032-56.jpg?semt=ais_hybrid`,
    },
    {
      id: 4,
      name: "Template 4",
      imgLink: `https://quickmenus.net/wp-content/uploads/2020/12/QR_FLYER.png`,
    },
    {
      id: 5,
      name: "Template 5",
      imgLink: `https://img.freepik.com/free-psd/flat-design-greetings-template_23-2150143770.jpg?semt=ais_hybrid`,
    },
    {
      id: 6,
      name: "Template 6",
      imgLink: `https://rlv.zcache.com/barcode_qr_code_upload_logo_template_custom_tarot-r13f6ccf9a6ac4a6e81b7aae57f32cf64_xqhn6j_306.jpg?rlvnet=1`,
    },
    {
      id: 7,
      name: "Template 7",
      imgLink: `https://d3jmn01ri1fzgl.cloudfront.net/photoadking/webp_thumbnail/whatsapp-qr-code-flyer-template-b6131ka372c206.webp`,
    },
    {
      id: 8,
      name: "Template 8",
      imgLink: `https://img.freepik.com/premium-psd/qr-code-standee-design-function-03_915032-56.jpg?semt=ais_hybrid`,
    },
    {
      id: 9,
      name: "Template 9",
      imgLink: `https://quickmenus.net/wp-content/uploads/2020/12/QR_FLYER.png`,
    },
    {
      id: 10,
      name: "Template 10",
      imgLink: `https://i.pinimg.com/474x/7f/51/86/7f5186328a8a4e4567fffc66a591c1b2.jpg`,
    },
    {
      id: 11,
      name: "Template 11",
      imgLink: `https://d3jmn01ri1fzgl.cloudfront.net/photoadking/webp_thumbnail/whatsapp-qr-code-flyer-template-b6131ka372c206.webp`,
    },
    {
      id: 12,
      name: "Template 12",
      imgLink: `https://rlv.zcache.com/barcode_qr_code_upload_logo_template_custom_tarot-r13f6ccf9a6ac4a6e81b7aae57f32cf64_xqhn6j_306.jpg?rlvnet=1`,
    },
    {
      id: 13,
      name: "Template 13",
      imgLink: `https://d3jmn01ri1fzgl.cloudfront.net/photoadking/webp_thumbnail/whatsapp-qr-code-flyer-template-b6131ka372c206.webp`,
    },
  ];
  return (
    <div className="w-full flex border border-[#D0D5DD] rounded-[10px] bg-white mt-6">
      <div className="w-[30%] border-r flex flex-col">
        <div className="text-[24px] leading-[22.27px] font-bold m-7">
          Select Template
        </div>
        <div className="grid grid-cols-2 pb-2 px-2 h-[68vh] overflow-hidden overflow-y-auto sidebar-scroll">
          {templateData?.map((temp) => {
            return (
              <button
                type="button"
                key={temp?.id}
                className="flex flex-col items-center justify-center border border-transparent hover:opacity-70 duration-300 ease-[0.3s] hover:border hover:border-base-primary py-6"
              >
                <div className="text-[16px] leading-5 text-[#000000] font-medium ">
                  {temp?.name}
                </div>
                <img
                  src={temp?.imgLink}
                  alt={temp?.name}
                  className="h-[127px] w-[103px] mt-4"
                />
              </button>
            );
          })}
        </div>
      </div>
      <div className="w-[70%]">
        <div className="w-full border-b">
          <div className="flex justify-end gap-4 my-5 pr-2">
            <CancelButton onClick={close} />
            <button
              type="button"
              className="flex items-center justify-center bg-base-primary hover:bg-base-primary_hover gap-4 duration-300 ease-[0.3s] rounded-md px-4"
            >
              <RiDownload2Line size={18} className="text-[#F6F8F9]" />
              <div className="text-[14px] leading-[14px] text-[#F6F8F9] font-semibold">
                Download Template
              </div>
            </button>
          </div>
        </div>
        <div className="w-full h-[68vh] border border-red-600 "></div>
      </div>
    </div>
  );
};

export default SelectTemplate;
