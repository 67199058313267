import React from "react";
import ToolTip from "../common/ToolTip";

const UnitsMeassureInputfield = ({
  label = false,
  value,
  onChange,
  type = "text",
  max,
  min,
  maxLength,
  minLength,
  message,
  placeholder,
  required = false,
  readOnly = false,
  options,
  setSelectValue,
  selectValue,
  className = "w-full",
  selectClassName = "w-[40%]",
}) => {
  return (
    <div className="w-full text-[16px] leading-6 font-normal text-[#667085]">
      <div className={`flex items-center justify-between`}>
        {label ? <label>{label}</label> : null}
        <ToolTip Message={message} />
      </div>
      <div className="relative flex items-center">
        <input
          className={`${className} border-r-0 focus:border-r-0 `}
          type={type}
          value={value}
          onChange={onChange}
          max={max}
          min={min}
          maxLength={maxLength}
          minLength={minLength}
          placeholder={placeholder}
          required={required}
          readOnly={readOnly}
        />
        <select
          value={selectValue}
          onChange={(e) => setSelectValue(e.target.value)}
          className={`${selectClassName} text-[14px] leading-5 text-[#667085] capitalize absolute right-0 pl-2 pr-0`}
        >
          {Object.keys(options || {})?.map((item, index) => (
            <option value={item} key={index}>
              {options[item].replace("_", " ").toLowerCase()}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default UnitsMeassureInputfield;
