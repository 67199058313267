import React, { useEffect, useState } from "react";
import CustomeTable from "../../../components/widget/CustomeTable";
import AddButton from "../../../components/buttons/AddButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllMinibarAction,
  getMinibarAction,
} from "../../../lib/slices/room/roomMinibarSlice";
import ViewMinibar from "./ViewMinibar";
import { notifyWarning } from "../../../components/common/alert";
import OutlineButton from "../../../components/buttons/OutlineButton";
import EditMinibarList from "./EditMinibarList";
import AssignToRoom from "./AssignToRoom";
import RemoveFromRooms from "./RemoveFromRooms";
import CreateMinibarList from "./CreateMinibarList";

const RoomMinibarList = () => {
  const dispatch = useDispatch();
  const { hotel } = useSelector((state) => state.hotel);
  const { minibar } = useSelector((state) => state.minibar);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isCreateMinibarList, setIsCreateMinibarList] = useState(false);
  const [datas, setDatas] = useState([]);
  const [isViewMinibar, setIsViewMinibar] = useState(false);
  const [isEditMinibar, setIsEditMinibar] = useState(false);
  const [getEditMinibarData, setGetEditMinibarData] = useState(null);
  const [isAssignToRoom, setIsAssignToRoom] = useState(false);
  const [isRemoveFromRooms, setIsRemoveFromRooms] = useState(false);

  useEffect(() => {
    if (hotel?._id) {
      dispatch(getAllMinibarAction({ hotelId: hotel?._id }));
    }
  }, []);

  const getRequestOfItem = () => {
    const selectedId = selectedRows?.[0]?.minibarId;
    if (selectedId) {
      dispatch(
        getMinibarAction({ hotelId: hotel?._id, minibarId: selectedId })
      );
    }
  };

  useEffect(() => {
    if (minibar && Array.isArray(minibar)) {
      const data = minibar?.map((minibarData) => {
        return {
          minibarId: minibarData?._id,
          minibarConfigName: minibarData?.minibar,
          itemName: minibarData?.itemName || "optional",
        };
      });
      if (data?.length) {
        setDatas(data?.reverse());
      }
    }
  }, [minibar]);

  const viewMinibar = () => {
    getRequestOfItem();
    if (!selectedRows.length) {
      notifyWarning("Please selecte at once in row to be edit.");
      return;
    }
    setIsViewMinibar(true);
  };

  // edit minibar
  const handleEditMinibar = () => {
    getRequestOfItem();
    if (!selectedRows.length) {
      notifyWarning("Please selecte at once in row to be edit.");
      return;
    }
    setIsEditMinibar(true);
    setGetEditMinibarData(selectedRows[0]);
  };

  // delete minibar
  const handleDelete = () => {
    console.log("Delete Api...");
  };

  //  ==================================== Assign to room pending =======================================>
  const assignMinibarToRoom = () => {
    getRequestOfItem();
    if (!selectedRows.length) {
      notifyWarning("Please selecte at once in row to be edit.");
      return;
    }
    setIsAssignToRoom(true);
  };

  //  ================================== remove from to room pending======================================>
  const removeFromRooms = () => {
    if (!selectedRows.length) {
      notifyWarning("Please selecte at once in row to be edit.");
      return;
    }
    setIsRemoveFromRooms(true);
  };

  const columnDefs = [
    {
      headerName: "Minibar Id",
      field: "minibarId",
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Minibar Configuration List Name",
      field: "minibarConfigName",
      filter: true,
    },
  ];

  return (
    <div>
      <div className="flex items-center justify-end gap-4">
        <AddButton
          text={"Create Minibar List"}
          onClick={() => setIsCreateMinibarList(true)}
        />
        <OutlineButton
          active={isViewMinibar}
          text={"View Minibar"}
          onClick={viewMinibar}
        />
        <OutlineButton
          active={isAssignToRoom}
          text={"Assign to Room"}
          onClick={assignMinibarToRoom}
        />
        <OutlineButton
          active={isRemoveFromRooms}
          text={"Remove From Rooms"}
          onClick={removeFromRooms}
        />
      </div>
      {/*================ Create Minivar List ======================= */}
      {isCreateMinibarList ? (
        <CreateMinibarList close={() => setIsCreateMinibarList(false)} />
      ) : null}
      {/*================ view minibar======================= */}
      {isViewMinibar ? (
        <ViewMinibar
          close={() => setIsViewMinibar(false)}
          hotel={hotel}
          selectedRowsData={selectedRows}
        />
      ) : null}
      {/* =============== edit minibar =======================*/}
      {isEditMinibar ? (
        <EditMinibarList
          editData={getEditMinibarData}
          close={() => setIsEditMinibar(false)}
        />
      ) : null}
      {/* ============== assign to room ====================== */}
      {isAssignToRoom ? (
        <AssignToRoom close={() => setIsAssignToRoom(false)} />
      ) : null}
      {/* ============== Remove From Room Modal =============== */}
      {isRemoveFromRooms ? (
        <RemoveFromRooms close={() => setIsRemoveFromRooms(false)} />
      ) : null}

      <div className="mt-4">
        <CustomeTable
          size="h-[55vh]"
          tableName={"Room Minibar List Table"}
          rowData={datas}
          setSelectedRows={setSelectedRows}
          columnDefs={columnDefs}
          editFunc={handleEditMinibar}
          deleteFunc={handleDelete}
        />
      </div>
    </div>
  );
};

export default RoomMinibarList;
