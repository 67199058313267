import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReportsCard from "./ReportsCard";
import CustomeTable from "../../components/widget/CustomeTable";
import { LuArrowDownToLine } from "react-icons/lu";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import { GoListUnordered } from "react-icons/go";
import { AiOutlineFileSync } from "react-icons/ai";

const PurchasOrderReports = (props) => {
  // const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const { sales } = useSelector((state) => state.SalesReport);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    props.getAssetsFromToDateAll(
      props?.hotel?._id,
      props?.fromDate,
      props?.toDate
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.fromDate, props?.toDate]);

  useEffect(() => {
    if (sales && Array.isArray(sales) && sales?.length) {
      let expense = sales?.map((d) => {
        return {
          purchaseOrderNo: d?.purchaseOrderNo,
          productName: d?.productName,
          vendorName: d?.vendorName,
          dateOrdered: d?.dateOrdered,
          dateExpected: d?.dateExpected,
          tdateReceived: d?.dateReceived,
          totalCost: d?.totalCost,
        };
      });
      setDatas(expense);
    }
  }, [sales]);

  const tableData = [
    {
      id: "POID01",
      purchaseOrderNo: "P001",
      productName: "Potato",
      vendorName: "ABC Supplies",
      dateOrdered: "2024-01-12",
      dateExpected: "2024-01-12",
      dateReceived: "Not Received",
      totalCost: "$500",
    },
    {
      id: "POID02",
      purchaseOrderNo: "P002",
      productName: "Salt",
      vendorName: "ABC Supplies",
      dateOrdered: "2024-01-12",
      dateExpected: "2024-01-12",
      dateReceived: "2024-01-12",
      totalCost: "$500",
    },
    {
      id: "POID03",
      purchaseOrderNo: "P003",
      productName: "Potato",
      vendorName: "ABC Supplies",
      dateOrdered: "2024-01-12",
      dateExpected: "2024-01-12",
      dateReceived: "Not Received",
      totalCost: "$500",
    },
    {
      id: "POID04",
      purchaseOrderNo: "P004",
      productName: "Potato",
      vendorName: "ABC Supplies",
      dateOrdered: "2024-01-12",
      dateExpected: "2024-01-12",
      dateReceived: "2024-01-12",
      totalCost: "$500",
    },
    {
      id: "POID05",
      purchaseOrderNo: "P005",
      productName: "Potato",
      vendorName: "ABC Supplies",
      dateOrdered: "2024-01-12",
      dateExpected: "2024-01-12",
      dateReceived: "Not Received",
      totalCost: "$500",
    },
  ];
  const checkDateReceived = ({ value }) => {
    return (
      <>
        <div className="text-[14px] leading-6 font-normal tracking-[-0.6%] mt-2">
          {value === "Not Received" ? (
            <div className="text-[#F04438]">{value}</div>
          ) : (
            <div>{value}</div>
          )}
        </div>
      </>
    );
  };
  const columnDefs = [
    {
      headerName: "Purchase Order ID",
      field: "id",
      minWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Purchase Order No.",
      field: "purchaseOrderNo",
      filter: true,
    },
    {
      headerName: "Product Name",
      field: "productName",
      filter: true,
    },
    {
      headerName: "Vendor Name",
      field: "vendorName",
      filter: true,
    },
    {
      headerName: "Date Ordered",
      field: "dateOrdered",
      filter: true,
    },
    {
      headerName: "Date Expected",
      field: "dateExpected",
      filter: true,
    },
    {
      headerName: "Date Received",
      field: "dateReceived",
      filter: true,
      cellRenderer: checkDateReceived,
    },
    {
      headerName: "Total Cost",
      field: "totalCost",
      filter: true,
    },
  ];

  return (
    <div className="page-container">
      <div className="grid grid-cols-4 items-center gap-4 my-4">
        <ReportsCard
          icon={<GoListUnordered />}
          className={"bg-[#F1FADF]"}
          heading={"Total Purchases"}
          value={"1258"}
        />
        <ReportsCard
          icon={<AiOutlineFileSync />}
          className={"bg-[#E8EBFF]"}
          heading={"Pending Orders"}
          value={"800"}
        />
        <ReportsCard
          icon={<LuArrowDownToLine />}
          className={"bg-[#FCEEDD]"}
          heading={"Top Vendor"}
          value={"ABC Supplies"}
        />
        <ReportsCard
          icon={<HiOutlineCurrencyDollar />}
          className={"bg-[#FCEAEA]"}
          heading={"Total Value"}
          value={"425"}
        />
      </div>

      {/* {datas && datas?.length ? ( */}
      <CustomeTable
        tableName={"Purchase Order Report table"}
        columnDefs={columnDefs}
        rowData={tableData}
        setSelectedRows={setSelectedRows}
      />
      {/* ) : null} */}
    </div>
  );
};

export default PurchasOrderReports;
