import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TIME_ZONES,
  PROPERTY_TYPE,
  COUNTRY_CURRENCY,
} from "../../../constants";
import { registerEvent } from "../../../logs";
import PropertyCurrencySelector from "./PropertyCurrencySelector";
import InputPropertyTimeZone from "./InputPropertyTimeZone";
import { useTranslation } from "react-i18next";
import Inputfield from "../../../components/form/InputField";
import SkipButton from "../../../components/buttons/SkipButton";
import AddSubmitButton from "../../../components/buttons/AddSubmitButton";
import CountryNameSelector from "../../../components/form/CountryNameSelector";
import StateNameSelector from "../../../components/form/StateNameSelector";
import { IoSearch } from "react-icons/io5";
import SelectFromObject from "../../../components/form/SelectFromObject";
import { updateHotelAction } from "../../../lib/slices/hotel/hotelSlice";

const PropertyDetails = ({ skipTheTab }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hotel, loading } = useSelector((state) => state.hotel);
  const [propertyName, setPropertyName] = useState("");
  const [timeZoneOffset, setTimeZoneOffset] = useState(TIME_ZONES[0]?.offset);
  const [propertyType, setPropertyType] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [tagline, setTagline] = useState("");
  const [taxIdNumber, setTaxIdNumber] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [coperateIdNumber, setCoperateIdNumber] = useState("");
  const [currency, setCurrency] = useState(COUNTRY_CURRENCY[0]?.code);
  const [checkInTime, setCheckInTime] = useState("");
  const [checkOutTime, setCheckOutTime] = useState("");
  const [country, setCountry] = useState("");
  const [countryIsoCode, setCountryIsoCode] = useState("");
  const [state, setState] = useState("");
  const [stateIsoCode, setStateIsoCode] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [searchInvoiceTem, setSearchInvoiceTem] = useState("");

  //set property details from local / hotel data
  useEffect(() => {
    if (hotel && hotel?._id) {
      setPropertyName(hotel?.propertyName);
      setPropertyType(hotel?.propertyType);
      setTimeZoneOffset(hotel?.timeZoneOffset);
      setEmail(hotel?.propertyContact?.email);
      setWebsite(hotel?.website);
      setTaxIdNumber(hotel?.taxIdNumber);
      setHsnCode(hotel?.HsnCode);
      setCoperateIdNumber(hotel?.coperateIdNumber);
      setCurrency(hotel?.currency);
      setCheckInTime(hotel?.checkInTime);
      setCheckOutTime(hotel?.checkOutTime);
      setTagline(hotel?.tagline);
    }
  }, [hotel]);

  //submit the form
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      hotelId: hotel?._id,
      propertyName,
      propertyType,
      email,
      website,
      taxIdNumber: taxIdNumber ? taxIdNumber : null,
      HsnCode: hsnCode ? hsnCode : null,
      coperateIdNumber: coperateIdNumber ? coperateIdNumber : null,
      currency: currency ? currency : null,
      tagline: tagline ? tagline : null,
      timeZoneOffset,
      checkInTime,
      checkOutTime,
    };

    dispatch(updateHotelAction(formData));
    registerEvent("UPDATE PROPERTY DETAILS", "PROPERTY DETAILS", formData);
  };

  return (
    <div className="">
      <div className="w-full max-w-full border border-[#E4E4E7] bg-white rounded-lg p-6">
        <h2 className="font-bold text-[30px] leading-[37.8px] text-[#07090D] tracking-[-1.5%]">
          {t("360")}
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3 my-4">
            <Inputfield
              label={t("689")}
              message={t("690")}
              value={propertyName}
              onChange={(e) => setPropertyName(e.target.value)}
              required={true}
              minLength={3}
              maxLength={40}
            />
            <SelectFromObject
              value={propertyType}
              setValue={setPropertyType}
              options={PROPERTY_TYPE}
              label={`${t("363")} *`}
              toolTipMessage={t("364")}
              required={true}
            />
            <InputPropertyTimeZone
              value={timeZoneOffset}
              setValue={setTimeZoneOffset}
              options={TIME_ZONES}
              required={true}
            />
            <Inputfield
              label={t("691")}
              message={t("692")}
              value={email}
              onChange={(e) => setEmail(e?.target?.value)}
              type="email"
              placeholder={"Enter Your Property Email"}
            />
            <Inputfield
              label={t("693")}
              message={t("694")}
              value={website}
              placeholder={"Enter a Value"}
              onChange={(e) => setWebsite(e?.target.value)}
              type="ulr"
            />
            <Inputfield
              label={t("695")}
              message={t("696")}
              value={taxIdNumber}
              placeholder={"Enter a Value"}
              onChange={(e) => setTaxIdNumber(e?.target?.value)}
            />
            <Inputfield
              label={t("697")}
              message={t("698")}
              placeholder={"Enter a Value"}
              value={hsnCode}
              onChange={(e) => setHsnCode(e?.target?.value)}
            />
            <Inputfield
              label={t("699")}
              message={t("700")}
              placeholder={"Enter a Value"}
              value={coperateIdNumber}
              onChange={(e) => setCoperateIdNumber(e?.target?.value)}
            />
            <PropertyCurrencySelector
              label={`${t("377")} *`}
              value={currency}
              onChange={(e) => setCurrency(e?.target?.value)}
            />

            <CountryNameSelector
              country={country}
              setCountry={setCountry}
              setCountryIsoCode={setCountryIsoCode}
            />
            <StateNameSelector
              state={state}
              setState={setState}
              stateIsoCode={stateIsoCode}
              setStateIsoCode={setStateIsoCode}
              countryCode={countryIsoCode}
            />
            <Inputfield
              label={`${t("Street Address")} *`}
              placeholder={t("Street Address")}
              value={streetAddress}
              onChange={(e) => setStreetAddress(e.target.value)}
              minLength={10}
              maxLength={100}
            />
            <Inputfield
              label={"Zip Code"}
              placeholder={t("Code")}
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              minLength={5}
              maxLength={100}
            />

            <Inputfield
              label={t("701")}
              message={t("702")}
              value={checkInTime}
              onChange={(e) => setCheckInTime(e?.target?.value)}
              type="time"
              required={true}
            />
            <Inputfield
              label={t("703")}
              message={t("704")}
              value={checkOutTime}
              onChange={(e) => setCheckOutTime(e?.target?.value)}
              type="time"
              required={true}
            />
          </div>
          {/* image */}

          {/* Invoice Template  */}
          <h2 className="font-semibold text-[24px] leading-6 text-black mt-6 mb-3">
            Invoice Template
          </h2>

          <div className="grid grid-cols-3">
            <div className="relative flex items-end gap-4">
              <IoSearch
                size={24}
                className="text-[#D0D5DD] absolute left-4 top-8 z-20"
              />
              <Inputfield
                customInputField={"pl-12"}
                message={"select invoice template"}
                label={"Invoice Template"}
                placeholder={"Type To Search"}
                value={searchInvoiceTem}
                onChange={() => setSearchInvoiceTem}
              />
            </div>
          </div>
          <div className="mt-8">
            <div className="text-[24px] leading-[30.24px] font-medium mb-3">
              Invoice No 1
            </div>
            <div className="w-[22%] h-[40vh] border border-base-primary rounded-[5px] overflow-hidden">
              <img
                src={`https://design-assets.adobeprojectm.com/content/download/express/public/urn:aaid:sc:VA6C2:9af769bf-9377-5fc1-bc94-d944f70f98b9/component?assetType=TEMPLATE&etag=f16f1d450fcf42e986f952928694892c&revision=e9455724-78c9-4ce6-954a-5e9698b64655&component_id=40752c25-a51f-4e74-9a3b-c081ee30c7b3`}
                alt=""
                className="object-fill h-full w-full"
              />
            </div>
          </div>
          <div className="flex justify-end items-center gap-3 mb-6 mt-8">
            <SkipButton text={t("260")} onClick={skipTheTab} />
            <AddSubmitButton text={t("65")} width={88} disabled={loading} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default PropertyDetails;
