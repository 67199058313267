import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addRoomAmenityAction } from "../../../lib/slices/room/roomAmenitiesSlice";
import CloseButton from "../../../components/buttons/CloseButton";
import Inputfield from "../../../components/form/InputField";
import { RxCross2 } from "react-icons/rx";
import AddButton from "../../../components/buttons/AddButton";
import AddAmenity from "../../../components/modals/AddAmenity";
import { ROOM_AMENITY } from "../../../constants/room-amenities";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../components/common/alert";
import FormSubmitButton from "../../../components/buttons";

const CreateRoomAmenitiesList = ({ close }) => {
  const dispatch = useDispatch();
  const { hotel } = useSelector((state) => state.hotel);
  const { error, loading } = useSelector((state) => state.roomAmenity);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isAddAmenity, setIsAddAmenity] = useState(false);
  const [addAmenity, setAddAmenity] = useState("");
  const [addAmenityToList, setAddAmenityToList] = useState(ROOM_AMENITY);
  const [listName, setListName] = useState("");

  const submitAmenities = () => {
    const amenityData = {
      hotelId: hotel?._id,
      listName,
      amenities: selectedItems?.map((item) => ({
        amenity: item,
      })),
    };

    if (
      !listName ||
      !Array.isArray(selectedItems) ||
      selectedItems.length === 0
    ) {
      notifyWarning(
        error || "Please provide a valid list name and at least one amenity."
      );
      return;
    }

    dispatch(addRoomAmenityAction(amenityData));

    if (!loading) {
      notifySuccess("Amenities added successfully.");
      setSelectedItems([]);
      setListName("");
      close();
    } else {
      notifyError(error || "Please Enter Valid Details.");
    }
  };

  const handleAddAmenity = () => {
    if (addAmenity.trim()) {
      setAddAmenityToList((prev) => [...prev, addAmenity.trim()]);
      setAddAmenity("");
      setIsAddAmenity(false);
    }
  };

  const handleSelectAmenity = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem !== item)
      );
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleRemoveValue = (value) => {
    setSelectedItems(
      selectedItems.filter((selectedItem) => selectedItem !== value)
    );
  };

  return (
    <div className="relative w-full bg-white border border-[#D0D5DD] rounded-[10px] p-6 mt-4">
      <div className="text-center text-[30px] leading-[37.8px] text-[#07090D] font-bold">
        Create Room Amenities List
      </div>
      <div className="absolute right-6 top-8">
        <CloseButton onClick={close} />
      </div>
      <div className="w-2/5 mt-6">
        <Inputfield
          label={"Room Amenity List Name *"}
          value={listName}
          onChange={(e) => setListName(e.target.value)}
          placeholder={"Please Enter List Name"}
          message={"list name is category of amenity"}
          required={true}
          minLength={3}
          maxLength={100}
        />
      </div>
      <div className="w-full  overflow-hidden mt-6 border border-[#D0D5DD] pl-4 pt-4 rounded-[10px]">
        <div className="text-[24px] leading-[30.24px] font-bold tracking-[-1.5%] text-[#07090D]">
          Select Room Amenities
        </div>
        <ul className="bg-white  overflow-y-auto overflow-x-hidden dropdown-scroll flex flex-wrap text-base text-[#000000] capitalize gap-4 py-4 pr-2">
          {selectedItems.map((value) => (
            <div
              key={value}
              className="flex items-center justify-between px-4 py-2 border border-base-primary rounded-lg bg-[#00a35e33]"
            >
              <div>{value.replace("_", " ")}</div>
              <button
                type="button"
                onClick={() => handleRemoveValue(value)}
                className="ml-2"
              >
                <RxCross2
                  size={18}
                  className="transition-all ease-in-out duration-300 scale-90 hover:scale-110 hover:text-[#F04438]"
                />
              </button>
            </div>
          ))}

          {addAmenityToList?.map((item, id) => (
            <li key={id}>
              <button
                type="button"
                onClick={() => handleSelectAmenity(item, id)}
                className={`
                  cursor-pointer select-none h-12 px-4 flex items-center gap-3 border border-[#D0D5DD] rounded-lg`}
              >
                {/* {ROOM_AMENITIES[item].replace("_", " ")} */}
                {item}
              </button>
            </li>
          ))}
          <li>
            <AddButton
              className={"h-12"}
              text={"Add New"}
              onClick={() => setIsAddAmenity(true)}
            />
          </li>
        </ul>
      </div>
      {isAddAmenity ? (
        <AddAmenity
          close={() => setIsAddAmenity(false)}
          value={addAmenity}
          onChange={(e) => setAddAmenity(e.target.value)}
          onClick={handleAddAmenity}
        />
      ) : null}
      <div className="flex items-center justify-end gap-4 mt-6">
        <FormSubmitButton
          buttonText="save List"
          onSubmit={submitAmenities}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default CreateRoomAmenitiesList;
