import React, { useEffect, useState } from "react";
import CustomModal from "../../../components/modals/CustomModal";
import SelectFromObject from "../../../components/form/SelectFromObject";
import FormSubmitButton from "../../../components/buttons";
import { useSelector } from "react-redux";
import CustomeTable from "../../../components/widget/CustomeTable";
// import { useDispatch } from "react-redux";
// import { assignMinibarToRoomAction } from "../../../lib/slices/room/roomMinibarSlice";

const AssignToRoom = ({ close }) => {
  //   const dispatch = useDispatch();
  const { getAnMinibarItem } = useSelector((state) => state.getAnMinibarItem);
  const [roomNumber, setRoomNumber] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    if (getAnMinibarItem?.items && Array.isArray(getAnMinibarItem?.items)) {
      const data = getAnMinibarItem?.items.map((listItem) => {
        return {
          minibarListId: listItem?._id,
          quantity: listItem?.quantity?.netQuantity,
          measurementUnit: listItem?.quantity?.measurementUnit,
        };
      });
      if (data?.length) {
        setDatas(data);
      }
    }
  }, [getAnMinibarItem]);
  const handleSubmit = () => {
    const data = {
      hotelId: "",
      minibarId: "",
      roomNumbers: "object",
    };
    // dispatch(assignMinibarToRoomAction(data));
  };

  const columnDefs = [
    {
      headerName: "Quantity",
      field: "quantity",
      filter: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Quantity Measure",
      field: "measurementUnit",
      filter: true,
    },
  ];

  return (
    <CustomModal close={close} heading={"Assign minibar To Room"}>
      <div className="grid grid-cols-2 my-4">
        <SelectFromObject
          label={"Room Number"}
          value={roomNumber}
          setValue={setRoomNumber}
          options={{ num: "A-101" }}
        />
      </div>
      <div className="">
        <CustomeTable
          size="h-[35vh]"
          tableName={"Minibar Item List Table"}
          rowData={datas}
          setSelectedRows={setSelectedRows}
          columnDefs={columnDefs}
          showButtons={false}
        />
      </div>
      <div className="flex items-center justify-end mt-4">
        <FormSubmitButton buttonText="Assign" onSubmit={handleSubmit} />
      </div>
    </CustomModal>
  );
};

export default AssignToRoom;
