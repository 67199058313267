import React from "react";
import CustomModal from "../../../components/modals/CustomModal";

const ViewMinibarList = ({ close }) => {
  return (
    <CustomModal close={close} heading={"Vew minibra list"}>
      <div className=""></div>
    </CustomModal>
  );
};

export default ViewMinibarList;
