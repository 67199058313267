import React, { useState } from "react";
import CustomeTable from "../../../components/widget/CustomeTable";

export const YesNo = () => {
  return (
    <div className={true ? "text-base-primary" : "text-[#F04438]"}>
      {true ? "Yes" : "No"}
    </div>
  );
};

const AllRoomType = (props) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const rowData = [
    {
      _id: 1255472734423,
      roomId: 2389,
      roomTypeName: "Delux",
      numberOfRooms: 3,
      roomNumbers: [123, 2323, 342],
      adults: 3,
      children: 2,
      ac: true,
      balcony: true,
      smoking: true,
      amenities: "See All Amenities ",
      price: 343,
      id: 23432,
    },
    {
      _id: 1255472734423,
      roomId: 2389,
      roomTypeName: "Delux",
      numberOfRooms: 3,
      roomNumbers: [123, 2323, 342],
      adults: 3,
      children: 2,
      ac: true,
      balcony: true,
      smoking: true,
      amenities: "See All Amenities ",
      price: 343,
      id: 23432,
    },
    {
      _id: 1255472734423,
      roomId: 2389,
      roomTypeName: "Delux",
      numberOfRooms: 3,
      roomNumbers: [123, 2323, 342],
      adults: 3,
      children: 2,
      ac: true,
      balcony: true,
      smoking: true,
      amenities: "See All Amenities ",
      price: 343,
      id: 23432,
    },
    {
      _id: 1255472734423,
      roomId: 2389,
      roomTypeName: "luxury",
      numberOfRooms: 3,
      roomNumbers: [123, 2323, 342],
      adults: 3,
      children: 2,
      ac: true,
      balcony: true,
      smoking: true,
      amenities: "See All Amenities ",
      price: 343,
      id: 23432,
    },
    {
      _id: 1255472734423,
      roomId: 2389,
      roomTypeName: "single",
      numberOfRooms: 3,
      roomNumbers: [123, 2323, 342],
      adults: 3,
      children: 2,
      ac: true,
      balcony: true,
      smoking: true,
      amenities: "See All Amenities ",
      price: 343,
      id: 23432,
    },
  ];
  const columnDefs = [
    {
      headerName: "Room ID",
      field: "_id",
      minWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Room Type Name",
      field: "roomTypeName",
      filter: true,
    },
    {
      headerName: "Rooms",
      field: "numberOfRooms",
      filter: true,
    },
    {
      headerName: "Room No",
      field: "roomNumbers",
      filter: true,
    },
    {
      headerName: "aduls",
      field: "aduls",
      filter: true,
    },
    {
      headerName: "children",
      field: "children",
      filter: true,
    },
    {
      headerName: "ac",
      field: "ac",
      cellRenderer: YesNo,
      filter: true,
    },
    {
      headerName: "balcony",
      field: "balcony",
      cellRenderer: YesNo,
      filter: true,
    },
    {
      headerName: "smoking",
      field: "smoking",
      cellRenderer: YesNo,
      filter: true,
    },
    {
      headerName: "Amenities",
      field: "amenities",
      filter: true,
    },
    {
      headerName: "price",
      field: "price",
      filter: true,
    },
    {
      headerName: "Actions",
      field: "id",
      minWidth: 200,
      // cellRenderer: RoomAction,
    },
  ];

  return (
    <CustomeTable
      tableName={"Room Type List Table"}
      rowData={rowData}
      setSelectedRows={setSelectedRows}
      columnDefs={columnDefs}
    />
  );
};

// const mapStateToProps = (state) => ({
//   rooms: state.AllRoom,
//   delete: state.DeleteRoom,
// });

// const mapDispatchToProps = (dispatch) => ({
//   deleteRoom: (roomId, hotelId) => dispatch(deleteRoomAction(roomId, hotelId)),
//   deletereset: () => dispatch({ type: DELETE_ROOM_RESET }),
//   clear: () => dispatch(clearError()),
//   getRooms: (hotelId) => dispatch(getAllRoomAction(hotelId)),
// });

export default AllRoomType;
