import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseButton from "../../../components/buttons/CloseButton";
import Inputfield from "../../../components/form/InputField";
import SelectFromObject from "../../../components/form/SelectFromObject";
import InputTextArea from "../../../components/form/InputTextArea";
import { SERVICE_TYPE } from "../../../constants";
import { addServiceAction } from "../../../lib/slices/room/roomService";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../components/common/alert";
import FormSubmitButton from "../../../components/buttons";

const AddServices = ({ close }) => {
  const dispatch = useDispatch();
  const { hotel } = useSelector((state) => state.hotel);
  const { error, loading } = useSelector((state) => state.roomService);

  const [formData, setFormData] = useState({
    serviceName: "",
    serviceType: [],
    servicePrice: "",
    timeStart: "",
    timeEnd: "",
    description: "",
  });

  const handleChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = () => {
    const addServiceData = {
      hotelId: hotel?._id,
      name: formData?.serviceName,
      category: formData?.serviceType?.toString(),
      charges: {
        netPrice: Number(formData?.servicePrice) || 0,
        currency: "INR", // Default currency
      },
      hours: {
        start: formData?.timeStart,
        end: formData?.timeEnd,
      },
      description: formData?.description,
    };

    if (
      !formData?.serviceName ||
      !formData?.serviceType ||
      formData?.serviceType.length === 0 ||
      !formData?.timeStart ||
      !formData?.timeEnd
    ) {
      notifyWarning(
        error ||
          "Please provide a valid service name and at least one service type and service Time."
      );
      return;
    }
    if (hotel?._id) {
      dispatch(addServiceAction(addServiceData));
    }
    if (!loading) {
      notifySuccess(error || "Service added successfully.");
      setFormData({
        serviceName: "",
        serviceType: [],
        servicePrice: "",
        timeStart: "",
        timeEnd: "",
        description: "",
      });
      close();
    } else {
      notifyError(error || "Please enter valid details.");
    }
  };

  return (
    <div className="relative w-full border border-[#E5E9EB] rounded-[10px] bg-white capitalize p-6">
      <div className="text-[30px] leading-[37.8px] tracking-[-1.5%] text-[#07090D] text-center font-bold">
        Add Service
      </div>
      <div className="absolute right-6 top-8">
        <CloseButton onClick={close} />
      </div>
      <div className="grid grid-cols-3 items-center gap-6 mt-6">
        <Inputfield
          label="Service Name *"
          placeholder="Enter Service name"
          value={formData.serviceName}
          onChange={(e) => handleChange("serviceName", e.target.value)}
          required={true}
          min={3}
          max={30}
        />
        <SelectFromObject
          label="Service Type *"
          options={SERVICE_TYPE}
          value={formData.serviceType}
          setValue={(value) => handleChange("serviceType", value)}
          toolTipMessage="Select Service type"
          required={true}
        />
        <Inputfield
          label="Service Price"
          placeholder="Enter Service Price"
          value={formData.servicePrice}
          onChange={(e) => handleChange("servicePrice", e.target.value)}
          min={3}
          max={30}
        />
      </div>
      <div className="text-[20px] leading-5 font-bold my-4">
        Service Timings
      </div>
      <div className="grid grid-cols-3 gap-6">
        <Inputfield
          label="Start *"
          type="time"
          value={formData.timeStart}
          onChange={(e) => handleChange("timeStart", e.target.value)}
        />
        <Inputfield
          label="End *"
          type="time"
          value={formData.timeEnd}
          onChange={(e) => handleChange("timeEnd", e.target.value)}
        />
      </div>
      <div className="mt-4">
        <InputTextArea
          rows={3}
          label="Description"
          placeholder="Note..."
          value={formData.description}
          onChange={(e) => handleChange("description", e.target.value)}
          minLength={10}
          maxLength={500}
        />
      </div>
      <div className="flex items-center justify-end mt-4 mb-2">
        <FormSubmitButton
          buttonText="Add Service"
          onSubmit={handleSubmit}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default AddServices;
