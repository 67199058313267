import React from "react";
import CustomModal from "../../../components/modals/CustomModal";
import AddMinibarItems from "./AddMinibarItems";

const AddItem = ({
  close,
}) => {
  return (
    <CustomModal close={close} heading={"Add Item To List"}>
      <AddMinibarItems isStyle={false} close={close} />
    </CustomModal>
  );
};

export default AddItem;
