import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authAxios from "../../services/authAxios";

//get all amenities action
export const getRoomAmenitiesAction = createAsyncThunk(
  "room/getRoomAmenitiesAction",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId, category } = credentials;
      if (!hotelId && !category) return;
      // incomplete
      const url = `api/hotel/owner/rooms/amenity/all?hotelId=${hotelId}&category=${category}`;
      const response = await authAxios.get(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// add amenity action
export const addRoomAmenityAction = createAsyncThunk(
  "room/addAmenityAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `/api/hotel/owner/rooms/amenity`;
      const response = await authAxios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);
// update amenity action
export const updateRoomAmenityAction = createAsyncThunk(
  "room/updateAmenityAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `/api/hotel/owner/rooms/amenity`;
      const response = await authAxios.put(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// delete hotel amenity action
export const deleteRoomAmenityAction = createAsyncThunk(
  "room/deleteRoomAmenityAction",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId, amenityId } = credentials;
      const url = `api/hotel/owner/rooms/amenity`;
      const response = await authAxios.delete(url, {
        data: { hotelId, amenityId },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//  add clear error state
// Helper function for handling pending state
const handlePending = (state) => {
  state.loading = true;
  state.error = null;
};

// Helper function for handling fulfilled state
const handleFulfilled = (state, action) => {
  state.loading = false;
  state.amenity = action.payload;
};

// Helper function for handling rejected state
const handleRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload || "An error occurred";
};

// Add amenity slice
export const roomAmenitySlice = createSlice({
  name: "room/amenitiesSlice",
  initialState: {
    loading: false,
    amenity: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoomAmenitiesAction.pending, handlePending)
      .addCase(getRoomAmenitiesAction.fulfilled, handleFulfilled)
      .addCase(getRoomAmenitiesAction.rejected, handleRejected)
      .addCase(addRoomAmenityAction.pending, handlePending)
      .addCase(addRoomAmenityAction.fulfilled, handleFulfilled)
      .addCase(addRoomAmenityAction.rejected, handleRejected)
      .addCase(updateRoomAmenityAction.pending, handlePending)
      .addCase(updateRoomAmenityAction.fulfilled, handleFulfilled)
      .addCase(updateRoomAmenityAction.rejected, handleRejected)
      .addCase(deleteRoomAmenityAction.pending, handlePending)
      .addCase(deleteRoomAmenityAction.fulfilled, handleFulfilled)
      .addCase(deleteRoomAmenityAction.rejected, handleRejected);
  },
});
