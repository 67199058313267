import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CloseButton from "../../../components/buttons/CloseButton";
import InputField from "../../../components/form/InputField";
import RoomByNumber from "./RoomByNumber";
import SubmitButton from "../../../components/buttons/SubmitButton";
import { useSelector } from "react-redux";

const NewAddRoomType = ({ close }) => {
  const { t } = useTranslation();
  const {hotel} = useSelector((state)=>state.hotel)
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  // Main form data
  const [formData, setFormData] = useState({
    roomType: "",
    roomNumber:"1",
    minPrice: "",
    maxPrice: "",
  });

  // Array to store data for each room
  const [roomsData, setRoomsData] = useState([
    {
      roomNum: "",
      numberOfBeds: 1,
      bedTypes: "",
      allBedSizes: "",
      isAcRoom: false,
      isBalcony: false,
      isSmoking: false,
      roomAmenitiesList: "",
      roomMiniBarList: "",
    },
  ]);

// console.log("formdata and room data", formData,roomsData)
  






  // Handle changes in the main form
  const handleFormDataChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));

    // Adjust roomsData array when the number of rooms changes
    if (key === "roomNumber") {
      const roomCount = +value;
      setRoomsData((prev) => {
        const updatedRooms = [...prev];
        while (updatedRooms.length < roomCount) {
          updatedRooms.push({
            roomNum: "",
            numberOfBeds: 1,
            bedTypes: "",
            allBedSizes: "",
            isAcRoom: false,
            isBalcony: false,
            isSmoking: false,
            roomAmenitiesList: "",
            roomMiniBarList: "",
          });
        }
        return updatedRooms.slice(0, roomCount);
      });
    }
  };

  // Handle changes in a specific room's data
  const handleRoomDataChange = (index, key, value) => {
      setRoomsData((prev) => {
        const updatedRooms = [...prev];
        updatedRooms[index] = { ...updatedRooms[index], [key]: value };
        return updatedRooms;
      });
  };

  // Handle bulk select/deselect all
  const handleSelectAllChange = () => {
    const newState = !selectAll;
    setSelectAll(newState);
    setSelectedItems(
      roomsData.reduce((acc, _, index) => ({ ...acc, [index]: newState }), {})
    );
  };

  // Handle selection for an individual room
  const handleCheckboxChange = (index) => {
    setSelectedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // const handleBedConfigurationChange = (index, config) => {
  //   const updatedConfigs = [...bedConfigurations];
  //   updatedConfigs[index] = config;
  //   setBedConfigurations(updatedConfigs);
  // };
  
  // Get selected room data
  // const getSelectedRoomsData = () => {
  //   return roomsData.filter((_, index) => selectedItems[index] );
  // };

  // Handle form submission
  const handleFormSubmit = () => {
    const selectedRooms =  roomsData.filter((_, index) => selectedItems[index] )
    console.log("form data :", formData)
    console.log("Selected Rooms Data: ", selectedRooms);
    // Add form submission logic here
   const finalData = {
    hotelId:"",
    roomType:"",
    description:"not mention in ui",
    hotelRooms:[{
      bedNumber:"",
      type:"",
      details:"",
    }],
    // not mention in ui
    occupancy:{
      adult:1,
      children:1,
    },
    price:{maxPrice:"",minPrice:"",currency:hotel?.currency},
    assestConsumptioningBooking:[{assetId:"",qyantityToBeComsumed:1}]
   }
 console.log("final Data :", finalData)
  };
// =======================debugging========================
// console.log("selected Item By CheckedBox : ", selectedItems)
// console.log("get Selected fields Data : ", roomsData)


  return (
    <div className="relative border border-[#D0D5DD] rounded-lg bg-white overflow-auto mx-auto mt-6 py-6">
      <div className="text-center font-bold text-[30px] leading-[37.8px] text-[#07090D] tracking-[-1.5%]">
        {t("Add Room Type")}
      </div>
      <div className="absolute right-7 top-8">
        <CloseButton onClick={close} />
      </div>
      <div className="px-8 pt-4 pb-6">
        <div className="text-[20px] leading-5 font-bold text-[#07090D]">
          Room Information
        </div>
        <div className="grid grid-cols-10 gap-4 items-center mt-4">
          <div className="col-span-5">
            <InputField
            className={"text-[#667085]"}
              label={"Room Type Name"}
              value={formData.roomType}
              onChange={(e) =>
                handleFormDataChange("roomType", e.target.value)
              }
              placeholder={"For Example. Deluxe Room"}
              required={true}
              minLength={6}
              maxLength={100}
            />
          </div>
          <div className="col-span-2">
            <InputField
            className={"text-[#667085]"}
              type="number"
              label={"Number Of Rooms"}
              value={formData.roomNumber}
              onChange={(e) =>
                handleFormDataChange("roomNumber", e.target.value)
              }
              min={1}
              max={50}
              required={true}
            />
          </div>
          <div className="col-span-3">
            <div className="flex items-center">
              <InputField
                className={"text-[#667085] border-r-0 rounded-r-none px-2"}
                label={"Min Room Tariff"}
                value={formData.minPrice}
                onChange={(e) =>
                  handleFormDataChange("minPrice", e.target.value)
                }
                placeholder={"Min Room Tariff"}
              />
              <InputField
                className={"text-[#667085] rounded-l-none mt-[6px] px-2"}
                value={formData.maxPrice}
                onChange={(e) =>
                  handleFormDataChange("maxPrice", e.target.value)
                }
                placeholder={"Max Room Tariff"}
                message={"abc"}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Bulk Action */}
      <div className="">
        <div className="h-16 flex items-center gap-4 border-t last:border-b border-[#D0D5DD]">
          <div className="h-full flex items-center border-r border-[#D0D5DD] px-2">
            <input
              className="size-4 accent-current"
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
          </div>
          <div className="text-[20px] leading-5 font-bold text-[#07090D]">
            Bulk Action
          </div>
        </div>
        {[...Array(+formData.roomNumber)].map((_, index) => (
          <div
            key={index}
            className="flex w-full border-t last:border-b border-[#D0D5DD]"
          >
            <div className="flex pt-[18px] border-r border-[#D0D5DD] px-2">
              <input
                className="size-4 accent-current"
                type="checkbox"
                checked={selectedItems[index] || false}
                onChange={() => handleCheckboxChange(index)}
              />
            </div>
            <div className="w-full px-4 pt-4 pb-6">
              <RoomByNumber
                roomNo={index + 1}
                roomsData = {roomsData}
                handleChangeRooms = {handleRoomDataChange}
                index = {index}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="flex items-center justify-end mt-6 px-6">
        <SubmitButton className="w-44 h-12" text={"Add Room"} onClick={handleFormSubmit}/>
      </div>
    </div>
  );
};

export default NewAddRoomType;