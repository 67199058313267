import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authAxios from "../../services/authAxios";

//get all services action
export const getServicesAction = createAsyncThunk(
  "room/getServicesAction",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId } = credentials;
      if (!hotelId) return;
      const url = `api/hotel/owner/hotels/hotel/service/all?hotelId=${hotelId}`;
      const response = await authAxios.get(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// add service action
export const addServiceAction = createAsyncThunk(
  "room/addServiceAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `api/hotel/owner/hotels/hotel/service`;
      const response = await authAxios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// update service action
export const updateServiceAction = createAsyncThunk(
  "room/updateServiceAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `/api/hotel/owner/hotels/hotel/service`;
      const response = await authAxios.put(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// delete service action
export const deleteServiceAction = createAsyncThunk(
  "room/deleteServiceAction",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId, serviceId } = credentials;
      const url = `api/hotel/owner/hotels/hotel/service`;
      const response = await authAxios.delete(url, {
        data: { hotelId, serviceId },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Helper function for handling pending state
const handlePending = (state) => {
  state.loading = true;
  state.error = null;
};

// Helper function for handling fulfilled state
const handleFulfilled = (state, action) => {
  state.loading = false;
  state.service = action.payload;
};

// Helper function for handling rejected state
const handleRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload || "An error occurred";
};

export const roomServiceSlice = createSlice({
  name: "room/roomServiceSlice",
  initialState: {
    loading: false,
    service: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getServicesAction.pending, handlePending)
      .addCase(getServicesAction.fulfilled, handleFulfilled)
      .addCase(getServicesAction.rejected, handleRejected)
      .addCase(addServiceAction.pending, handlePending)
      .addCase(addServiceAction.fulfilled, handleFulfilled)
      .addCase(addServiceAction.rejected, handleRejected)
      .addCase(updateServiceAction.pending, handlePending)
      .addCase(updateServiceAction.fulfilled, handleFulfilled)
      .addCase(updateServiceAction.rejected, handleRejected)
      .addCase(deleteServiceAction.pending, handlePending)
      .addCase(deleteServiceAction.fulfilled, handleFulfilled)
      .addCase(deleteServiceAction.rejected, handleRejected);
  },
});
