import React from "react";
import { registerEvent } from "../../logs";
import CustomModal from "./CustomModal";
import CancelButton from "../buttons/CancelButton";

export default function DeleteOrNot({ isOpen, onClose, onConfirm }) {
  if (!isOpen) return null;
  //agree to delete
  const agree = () => {
    onConfirm();
    onClose();
    registerEvent("agree to delete", "delete or not delete modal");
  };

  //disagree to delete
  const disagree = () => {
    onClose();
    registerEvent("disagree to delete", "delete or not delete modal");
  };

  return (
    <CustomModal isCloseButton={false}>
      <div className="w-[25vw]">
        <h2 className="text-[18px] leading-[26px] font-normal tracking-[-1.5%] text-[#07090D]">
          Are you sure you want to delete this item?
        </h2>
        <div className="flex items-center gap-3 mt-5">
          <button
            type="button"
            onClick={agree}
            className="h-[37px] w-[118px] rounded-[5px] bg-[#F04438] hover:bg-[#d1281c] text-[14px] leading-[22px] font-normal text-white transition-all duration-[0.3s] ease-[0s]"
          >
            Yes,Delete it
          </button>
          <CancelButton onClick={disagree} />
        </div>
      </div>
    </CustomModal>
  );
}
