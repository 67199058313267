import React from "react";
import CustomModal from "../../../components/modals/CustomModal";

const ViewAmenitiesList = ({ close }) => {
  return (
    <CustomModal close={close} heading={"view Amenities list"}>
      <div className=""></div>
    </CustomModal>
  );
};

export default ViewAmenitiesList;
