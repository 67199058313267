import React from "react";
import CustomModal from "./CustomModal";
import Inputfield from "../form/InputField";
import SubmitButton from "../buttons/SubmitButton";

const AddAmenity = ({ close, value, onChange, onClick }) => (
  <CustomModal close={close} heading={"Add New Amenity"}>
    <div className="w-[30vw] flex flex-col gap-4 mt-4">
      <Inputfield
        value={value}
        onChange={onChange}
        placeholder={"Enter New Amenity Name..."}
      />
      <div className="flex items-center justify-end">
      <SubmitButton type="button" className="w-28 h-10" text={"Add"} onClick={onClick} />
      </div>
    </div>
  </CustomModal>
);

export default AddAmenity;
