import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RoomsHeader from "./RoomsHeader";
import FoodBuffetPrice from "./FoodBuffetPrice";
import PackagesPage from "./packages";
import RoomAmenities from "./room-amenities";
import RoomMinibarList from "./room-minibar/RoomMinibarList";
import RoomRate from "./RoomRate";
import ServicesPage from "./services";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import AllRooms from "./all-rooms/AllRooms";
import AllMinibarItems from "./room-minibar/AllMinibarItems";
import AddButton from "../../components/buttons/AddButton";
import RoomQRCode from "./RoomQrCode";


const RoomConfiguration = () => {
  const { t } = useTranslation();
  const [index, setIndex] = useState(1);

  const headingData = {
    heading: t("Room Configuration"),
    seperator: [
      {
        text: t("Hotel Configuration"),
      },
      {
        text: t("Room Configuration"),
      },
    ],
  };

  return (
    <div className="page-container">
      <div className="flex items-end justify-between">
        <Breadcrumbs data={headingData} />
        {index === 7 ? <AddButton text={"Genrate Code"} /> : null}
      </div>
      <div className="my-6">
        <RoomsHeader index={index} setIndex={setIndex} />
      </div>
      {index === 1 ? <RoomAmenities /> : null}
      {index === 2 ? <AllMinibarItems /> : null}
      {index === 3 ? <RoomMinibarList /> : null}
      {index === 4 ? <AllRooms /> : null}
      {index === 5 ? <FoodBuffetPrice /> : null}
      {index === 6 ? <RoomRate /> : null}
      {index === 7 ? <RoomQRCode /> : null}
      {index === 8 ? <ServicesPage /> : null}
      {index === 9 ? <PackagesPage /> : null}
    </div>
  );
};

export default RoomConfiguration;
