import React from "react";
import { useTranslation } from "react-i18next";
import { TbPencil } from "react-icons/tb";

const EditButton = ({
  onClick,
  disabled,
  className = "",
}) => {
  const { t } = useTranslation();
  return (
    <button
      className={`${className} h-10 px-4 border border-[#667085] rounded-md flex items-center justify-center gap-3 duration-300 ease-[0.3s] bg-white text-[#667085] text-sm font-semibold hover:text-white hover:bg-[#667085] group`}
      onClick={onClick}
      disabled={disabled}
      type="button"
    >
      <div className="flex items-center justify-center rounded text-white bg-[#667085] group-hover:bg-white group-hover:text-[#667085] p-[3px]">
        <TbPencil size={16} />
      </div>

      <span className="tracking-[-0.006em]">
        {t("62")}
      </span>
    </button>
  );
};

export default EditButton;
