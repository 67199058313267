import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReportsCard from "./ReportsCard";
import EmployeeHighAttendanceList from "./EmployeeHighAttendanceList";
import CustomeTable from "../../components/widget/CustomeTable";
import { IoBanOutline, IoCubeOutline } from "react-icons/io5";
import { LuFileSymlink } from "react-icons/lu";
import { HiOutlineCurrencyDollar } from "react-icons/hi";

const EmployeeAttandnce = (props) => {
  // const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const { sales } = useSelector((state) => state.SalesReport);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    props.getAssetsFromToDateAll(
      props?.hotel?._id,
      props?.fromDate,
      props?.toDate
    );
  }, [props?.fromDate, props?.toDate]);

  useEffect(() => {
    if (sales && Array.isArray(sales) && sales?.length) {
      let expense = sales?.map((d) => {
        return {
          date: d?.date,
          employeeID: d?.employeeID,
          employeeName: d?.employeeName,
          department: d?.department,
          startTime: d?.startTime,
          endTime: d?.endTime,
          attendance: d?.attendance,
          totalWorkingHours: d?.totalWorkingHours,
        };
      });
      setDatas(expense);
    }
  }, [sales]);

  const tableData = [
    {
      date: "2024-01-12",
      employeeID: "E001",
      employeeName: "John Doe",
      department: "Security",
      startTime: "12:00",
      endTime: "20:00",
      attendance: "Present",
      totalWorkingHours: "8",
    },
    {
      date: "2024-01-12",
      employeeID: "E001",
      employeeName: "John Doe",
      department: "Security",
      startTime: "12:00",
      endTime: "20:00",
      attendance: "Absent",
      totalWorkingHours: "8",
    },
    {
      date: "2024-01-12",
      employeeID: "E001",
      employeeName: "John Doe",
      department: "Security",
      startTime: "--",
      endTime: "--",
      attendance: "Leave",
      totalWorkingHours: "8",
    },
    {
      date: "2024-01-12",
      employeeID: "E001",
      employeeName: "John Doe",
      department: "Security",
      startTime: "12:00",
      endTime: "20:00",
      attendance: "Present",
      totalWorkingHours: "8",
    },
    {
      date: "2024-01-12",
      employeeID: "E001",
      employeeName: "John Doe",
      department: "Security",
      startTime: "12:00",
      endTime: "20:00",
      attendance: "Present",
      totalWorkingHours: "8",
    },
  ];

  const checkAttandance = ({ value }) => {
    return (
      <>
        <div className="text-[13px] leading-3 font-normal mt-2">
          {value === "Present" && (
            <div className="w-[66px] h-[26px] flex items-center justify-center bg-[#ECFDF3] rounded-[5px]  text-[#12B76A]">
              {value}
            </div>
          )}
          {value === "Leave" && (
            <div className="w-[66px] h-[26px] flex items-center justify-center bg-[#EEF4FF] rounded-[5px] text-[#444CE7]">
              {value}
            </div>
          )}
          {value === "Absent" && (
            <div className="w-[66px] h-[26px] flex items-center justify-center bg-[#FEF3F2] rounded-[5px] text-[#F04438]">
              {value}
            </div>
          )}
        </div>
      </>
    );
  };

  const columnDefs = [
    {
      headerName: "",
      field: "_id",
      maxWidth: 50,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Date",
      field: "date",
      filter: true,
    },
    {
      headerName: "Employee ID",
      field: "employeeID",
      filter: true,
    },
    {
      headerName: "Employee Name",
      field: "employeeName",
      filter: true,
    },
    {
      headerName: "Department",
      field: "department",
      filter: true,
    },
    {
      headerName: "Start Time",
      field: "startTime",
      filter: true,
    },
    {
      headerName: "End Time",
      field: "endTime",
      filter: true,
    },
    {
      headerName: "Attendance",
      field: "attendance",
      filter: true,
      cellRenderer: checkAttandance,
    },
    {
      headerName: "Total Working Hours",
      field: "totalWorkingHours",
      filter: true,
    },
  ];

  return (
    <div className="page-container">
      <div className="grid grid-cols-4 items-center gap-4 my-4">
        <ReportsCard
          icon={<IoCubeOutline />}
          className={"bg-[#F1FADF]"}
          heading={"Total Employees"}
          value={"1258"}
        />
        <ReportsCard
          icon={<LuFileSymlink />}
          className={"bg-[#E8EBFF]"}
          heading={"Regular Employees"}
          value={"800"}
        />
        <ReportsCard
          icon={<IoBanOutline />}
          className={"bg-[#FCEEDD]"}
          heading={"Contract Employees"}
          value={"1258"}
        />
        <ReportsCard
          icon={<HiOutlineCurrencyDollar />}
          className={"bg-[#FCEAEA]"}
          heading={"Temporaty Employees"}
          value={"$3000"}
        />
      </div>
      {/* {datas?.datas?.length ? ( */}
      <CustomeTable
        tableName={"Employee Attendance & weekly/Monthly Roaster Report"}
        columnDefs={columnDefs}
        rowData={tableData}
        setSelectedRows={setSelectedRows}
      />
      {/* ) : null} */}
      {/* Employee attendance list table */}
      <div className="mt-4">
        <EmployeeHighAttendanceList />
      </div>
    </div>
  );
};

export default EmployeeAttandnce;
