import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authAxios from "../../services/authAxios";

//get all Minibar action
export const getAllMinibarAction = createAsyncThunk(
  "room/getAllMinibarAction",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId } = credentials;
      if (!hotelId) return;
      const url = `api/hotel/owner/rooms/minibar/all?hotelId=${hotelId}`;
      const response = await authAxios.get(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);


// add minibar action
export const addMinibarAction = createAsyncThunk(
  "room/addMinibarAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `api/hotel/owner/rooms/minibar`;
      const response = await authAxios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// update minibar action
export const updateMinibarAction = createAsyncThunk(
  "room/updateMinibarAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `api/hotel/owner/rooms/minibar`;
      const response = await authAxios.put(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// assign minibar to room
export const assignMinibarToRoomAction = createAsyncThunk(
  "room/assignMinibarToRoomAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `api/hotel/owner/rooms/minibar/assign`;
      const response = await authAxios.put(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);
 
// remove minibar to room
export const removeMinibarFromRoomAction = createAsyncThunk(
  "room/assignMinibarAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `api/hotel/owner/rooms/minibar/assign`;
      const response = await authAxios.put(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);
//get a Minibar action
export const getMinibarAction = createAsyncThunk(
    "room/getMinibarAction",
    async (credentials, thunkAPI) => {
      try {
        const { hotelId, minibarId } = credentials;
        if (!hotelId && !minibarId) return;
        const url = `api/hotel/owner/rooms/minibar?hotelId=${hotelId}&minibarId=${minibarId}`;
        const response = await authAxios.get(url);
        return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error?.response?.data?.message);
      }
    }
  );
  export const roomMinibarAnItemSlice = createSlice({
    name: "room/roomMinibarAnItemSlice",
    initialState: {
      loading: false,
      getAnMinibarItem: null,
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getMinibarAction.pending, (state)=>{
            state.loading = true;
            state.error = null;
        })
        .addCase(getMinibarAction.fulfilled, (state, action)=>{
            state.loading = false;
            state.getAnMinibarItem = action.payload;
        })
        .addCase(getMinibarAction.rejected, (state, action)=>{
            state.loading = false;
            state.error = action.payload || "An error occurred";
        })
    }})

// Helper function for handling pending state
const handlePending = (state) => {
  state.loading = true;
  state.error = null;
};

// Helper function for handling fulfilled state
const handleFulfilled = (state, action) => {
  state.loading = false;
  state.minibar = action.payload;
};

// Helper function for handling rejected state
const handleRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload || "An error occurred";
};

export const roomMinibarSlice = createSlice({
  name: "room/roomMinibarSlice",
  initialState: {
    loading: false,
    minibar: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMinibarAction.pending, handlePending)
      .addCase(getAllMinibarAction.fulfilled, handleFulfilled)
      .addCase(getAllMinibarAction.rejected, handleRejected)
      .addCase(addMinibarAction.pending, handlePending)
      .addCase(addMinibarAction.fulfilled, handleFulfilled)
      .addCase(addMinibarAction.rejected, handleRejected)
      .addCase(updateMinibarAction.pending, handlePending)
      .addCase(updateMinibarAction.fulfilled, handleFulfilled)
      .addCase(updateMinibarAction.rejected, handleRejected)
      .addCase(assignMinibarToRoomAction.pending, handlePending)
      .addCase(assignMinibarToRoomAction.fulfilled, handleFulfilled)
      .addCase(assignMinibarToRoomAction.rejected, handleRejected)
      .addCase(removeMinibarFromRoomAction.pending, handlePending)
      .addCase(removeMinibarFromRoomAction.fulfilled, handleFulfilled)
      .addCase(removeMinibarFromRoomAction.rejected, handleRejected);
  },
});
