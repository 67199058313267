import React, { useState } from "react";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import QrCodeHistory from "./QrCodeHistory";
import AddButton from "../../components/buttons/AddButton";
import SelectTemplate from "./SelectTemplate";

const QrCodeManagement = () => {
  const [isOpenTem, setIsOpenTem] = useState(false);
  const breadcrumbsData = {
    heading: "QR Code Management",
    seperator: [
      {
        text: "Hotel Configuration",
      },
      {
        text: "Next",
      },
    ],
  };

  return (
    <div className="page-container">
      <div className="flex items-end justify-between">
        <Breadcrumbs data={breadcrumbsData} />
        <AddButton text={"Templates"} onClick={() => setIsOpenTem(true)} />
      </div>
      {isOpenTem ? <SelectTemplate close={() => setIsOpenTem(false)} /> : null}
      <div className="mt-4">
        <QrCodeHistory />
      </div>
    </div>
  );
};

export default QrCodeManagement;
