import React from "react";

const AddRoomTypeCheckBox = ({ value, setValue, label, isRequired = false, className }) => {
  return (
    <>
      <div
        className={`w-fit relative flex justify-between items-center py-2 h-10`}
      >
        <input
          type={"checkbox"}
          checked={value}
          id={label}
          onChange={() => setValue(!value)}
          value={value}
          className={"checkBoxControl mr-2"}
          required={isRequired}
        />
        <label
          className={`${className} text-sm text-[#344054] capitalize`}
          htmlFor={label}
        >
          {label}
        </label>
        
      </div>
    </>
  );
};

export default AddRoomTypeCheckBox;
