import React from "react";
import CloseButton from "../../../components/buttons/CloseButton";
import { useSelector } from "react-redux";

const AddToRoomAmenities = ({ close }) => {
    // const { amenity, loading, error } = useSelector((state) => state.roomAmenity);
   
  return (
    <div className="w-full  h-[20vh] border border-[#D0D5DD] rounded-[10px] bg-white mt-4 p-6">
      <div className="flex items-center justify-between">
        <div className="text-[26px] leading-[32.76px] text-[#07090D] font-bold">
          Add Room To Amenities List Type
        </div>
        <CloseButton onClick={close} />
      </div>
      <div className="text-4xl flex items-center justify-center">
        api not match updated ui
      </div>
    </div>
  );
};

export default AddToRoomAmenities;
