import React, { useEffect, useState } from "react";
import CustomModal from "../../../components/modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../components/common/alert";
import { updateMinibarAction } from "../../../lib/slices/room/roomMinibarSlice";
import SelectBox from "../../../components/form/SelectBox";
import AddItem from "./AddItem";
import ShiftButton from "../../../components/buttons/ShiftButton";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import Inputfield from "../../../components/form/InputField";
import FormSubmitButton from "../../../components/buttons";

const EditMinibarList = ({ close, editData }) => {
  const dispatch = useDispatch();
  const { hotel } = useSelector((state) => state.hotel);
  const { loading, error } = useSelector((state) => state.minibarItem);
  const { getAnMinibarItem } = useSelector((state) => state.getAnMinibarItem);
  const [isOpenAddItem, setIsOpenAddItem] = useState(false);
  // left side table
  const [leftSideData, setLeftSideData] = useState([]);
  const [leftSideRows, setLeftSideRows] = useState([]);
  // right side table
  const [rightSideRows, setRightSideRows] = useState([]);
  const [rightSideData, setRightSideData] = useState([]);

  const [formData, setFormData] = useState({
    minibarConfigName: editData?.minibarConfigName,
  });

  //   handle change
  const handleChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  // left side data
  useEffect(() => {
    if (getAnMinibarItem?.items && Array.isArray(getAnMinibarItem?.items)) {
      const data = getAnMinibarItem?.items.map((listItem) => {
        return {
          minibarListId: listItem?._id,
          quantity: listItem?.quantity?.netQuantity,
          measurementUnit: listItem?.quantity?.measurementUnit,
        };
      });
      if (data?.length) {
        setLeftSideData(data);
      }
    }
  }, [getAnMinibarItem]);

  // add to list
  const handleItemAddToList = () => {
    const addItemToListData = leftSideRows?.map((data) => ({
      minibarListId: data?.minibarListId || "",
      quantity: data?.quantity || "",
      measurementUnit: data?.measurementUnit || "",
    }));
    setRightSideData(addItemToListData);
  };
// remove to right side
  const handleItemRemoveToList = () => {
    const selectedId = rightSideRows?.map((data) => data?.minibarListId);
    const filteredItems = rightSideData?.filter(
      (data) => !selectedId?.includes(data?.minibarListId)
    );
    setRightSideData(filteredItems);
  };

  // update data
    const handleSubmit = () => {
      const itemsData = rightSideData?.map((data) => {
        console.log("update Minibar List", data);
        return {
          itemId: data.minibarListId,
        //   itemName: data?.foodAndBeveragesName || "item name optional",
          quantity: {
            netQuantity: data?.quantity,
            measurementUnit: data?.measurementUnit,
          },
        };
      });
      const minibarData = {
        hotelId: hotel?._id,
        minibarId:editData?.minibarId,
        minibar: formData?.minibarConfigName,
        items: itemsData,
      };
      if (!editData?.minibarConfigName || rightSideData.length === 0) {
        notifyWarning(
          "Please select item to Update."
        );
        return;
      }

      if(hotel?._id){
        dispatch(updateMinibarAction(minibarData));
      }

      if (!loading) {
        notifySuccess(error || "minibar Edit Successfully.");
        close();
      } else {
        notifyError(error || "Please Enter valid details.");
      }
    };

  const leftColumnDefs = [
    {
      headerName: "Quantity",
      field: "quantity",
      filter: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Quantity Measure",
      field: "measurementUnit",
      filter: true,
    },
  ];

  const rightColumnDefs = [
    {
      headerName: "Quantity",
      field: "quantity",
      filter: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Quantity Measure",
      field: "measurementUnit",
      filter: true,
    },
  ];
  return (
    <CustomModal
      close={close}
      heading={"Edit Configuration of Minibar By Room Type"}
    >
      <div className="w-[60vw] mt-4">
        <div className="w-2/5 ">
          <Inputfield
            label={"Minibar Configuration Name"}
            value={formData.minibarConfigName}
            onChange={(e) => handleChange("minibarConfigName", e.target.value)}
            placeholder={"Enter Minibar Configuration Name"}
          />
        </div>
        <div className="grid grid-cols-10 w-full mt-6">
          {/* Left side */}
          <div className="col-span-4">
            <SelectBox
              label={"Available Minibar Items"}
              heading={"Select Minibar Items to Add"}
              showBtn={false}
              rowData={leftSideData}
              columnDefs={leftColumnDefs}
              setSelectedRows={setLeftSideRows}
            />
          </div>
          {isOpenAddItem ? (
            <AddItem close={() => setIsOpenAddItem(false)} />
          ) : null}
          {/* Buttons for add and remove */}
          <div className="col-span-2 flex flex-col items-center justify-center gap-6">
            <ShiftButton
              name={"Add"}
              icon={<AiOutlineDoubleRight size={18} />}
              onClick={handleItemAddToList}
            />

            <ShiftButton
              name={"Remove"}
              icon={<AiOutlineDoubleLeft size={18} />}
              onClick={handleItemRemoveToList}
            />
          </div>

          {/* Right side */}
          <div className="col-span-4 w-full">
            <SelectBox
              label={"Selected Item to Edit"}
              heading={"Add items to List"}
              showBtn={false}
              rowData={rightSideData}
              columnDefs={rightColumnDefs}
              setSelectedRows={setRightSideRows}
            />
          </div>
        </div>
        <div className="flex items-center justify-end mt-6">
          <FormSubmitButton buttonText="Update Minibar" onSubmit={handleSubmit}/>
        </div>
      </div>
    </CustomModal>
  );
};

export default EditMinibarList;
