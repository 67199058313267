import React, { useState } from "react";
import CloseButton from "../../../components/buttons/CloseButton";
import Inputfield from "../../../components/form/InputField";
import SelectFromObject from "../../../components/form/SelectFromObject";
import UnitsMeassureInputfield from "../../../components/form/UnitsMeassureInputfield";
import {
  DIETARY_PREFERENCE,
  FOOD_BEVERAGE_TYPES,
  MEASUREMENT_UNITS,
  QUANTITY_PER_ITEM,
} from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../components/common/alert";
import { addMinibarItemAction } from "../../../lib/slices/room/roomMinibarItemSlice";
import FormSubmitButton from "../../../components/buttons";

const AddMinibarItems = ({ close, isStyle = true }) => {
  const dispatch = useDispatch();
  const { hotel } = useSelector((state) => state.hotel);
  const { loading, error } = useSelector((state) => state.minibarItem);

  const [formData, setFormData] = useState({
    foodAndBeveragesName: "",
    fAndBType: "",
    dietaryPreference: "",
    netQuantity: "",
    measurementUnit: "",
    netPrice: "",
    measurementUnitPerItem: "",
  });

  const handleChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = () => {
    const minibarItemData = {
      hotelId: hotel?._id,
      itemName: formData?.foodAndBeveragesName,
      itemType: formData?.fAndBType || "Snakcs",
      dietaryPreference: formData?.dietaryPreference || "Vegetarian",
      quantity: {
        netQuantity: formData?.netQuantity,
        measurementUnit: formData?.measurementUnit || "Ml",
      },
      price: {
        netPrice: Number(formData?.netPrice),
        measurementUnitPerItem: formData?.measurementUnitPerItem || "Per Item",
        currency: hotel?.currency,
      },
    };
    if (!formData.foodAndBeveragesName) {
      notifyWarning(error || "Please Enter All Fields are required.");
      return;
    }

    if (hotel?._id) {
      dispatch(addMinibarItemAction(minibarItemData));
    }

    if (!loading) {
      notifySuccess("Minibar item added successfully.");
    } else {
      notifyError(error || "Please enter valid details.");
    }
    setFormData({
      foodAndBeveragesName: "",
      fAndBType: "",
      dietaryPreference: "",
      netQuantity: "",
      measurementUnit: "",
      netPrice: "",
      measurementUnitPerItem: "",
    });
    close();
  };

  return (
    <div
      className={`${
        isStyle
          ? "w-full border border-[#D0D5DD] rounded-[10px] p-6 mt-4 "
          : "w-[50vw]"
      } relative  bg-white`}
    >
      {isStyle ? (
        <>
          <div className="text-center text-[26px] leading-[32.76px] font-bold text-[#07090D]">
            Add Mini Bar Item
          </div>
          <div className="absolute right-6 top-[30px]">
            <CloseButton onClick={close} />
          </div>
        </>
      ) : null}
      <div
        className={`${
          isStyle ? "px-14 gap-6" : "gap-4"
        } grid grid-cols-3  my-6`}
      >
        <Inputfield
          label={"Food & Beverages Name"}
          value={formData.foodAndBeveragesName}
          onChange={(e) => handleChange("foodAndBeveragesName", e.target.value)}
          placeholder={"Enter Food And Beverages Name"}
        />

        <SelectFromObject
          label={"F&B Type"}
          options={FOOD_BEVERAGE_TYPES}
          value={formData.fAndBType || "snack"}
          setValue={(value) => handleChange("fAndBType", value)}
        />

        <SelectFromObject
          label={"Dietary Preference"}
          options={DIETARY_PREFERENCE}
          value={formData.dietaryPreference || "veg"}
          setValue={(value) => handleChange("dietaryPreference", value)}
        />

        <UnitsMeassureInputfield
          label={"Quantity"}
          value={formData.netQuantity}
          selectValue={formData.measurementUnit || "ml"}
          onChange={(e) => handleChange("netQuantity", e.target.value)}
          options={MEASUREMENT_UNITS}
          setSelectValue={(value) => handleChange("measurementUnit", value)}
          placeholder={"Enter Quantity"}
          minLength={0}
        />

        <UnitsMeassureInputfield
          label={"Price"}
          value={formData.netPrice}
          selectValue={formData.measurementUnitPerItem || "perItems"}
          onChange={(e) => handleChange("netPrice", e.target.value)}
          options={QUANTITY_PER_ITEM}
          setSelectValue={(value) =>
            handleChange("measurementUnitPerItem", value)
          }
          placeholder={"Enter Price"}
          minLength={0}
        />
      </div>
      <div className="flex items-center justify-end">
        <FormSubmitButton
          buttonText="Add Minibar"
          onSubmit={handleSubmit}
          // loading={loading}
        />
      </div>
    </div>
  );
};

export default AddMinibarItems;
