import React, { useState } from 'react'
import CustomModal from '../../../components/modals/CustomModal';
import SelectFromObject from '../../../components/form/SelectFromObject';
import FormSubmitButton from '../../../components/buttons';

const RemoveFromRooms =  ({ close }) => {
    //   const dispatch = useDispatch();
    const [roomNumber, setRoomNumber] = useState([]);
  
    const handleSubmit = () => {
      const data = {
        hotelId: "",
        minibarId: "",
        roomNumbers: "object",
      };
      // dispatch(assignMinibarToRoomAction(data));
    };
    return (
      <CustomModal close={close} heading={"Remove minibar From Rooms"}>
        <div className="w-[24vw] my-[15px]">
          <SelectFromObject
            label={"Room Number"}
            value={roomNumber}
            setValue={setRoomNumber}
            options={{ num: "A-101" }}
          />
        </div>
        <div className="flex items-center justify-end">
          <FormSubmitButton buttonText="Assign" onSubmit={handleSubmit} />
        </div>
      </CustomModal>
    );
  };
  

export default RemoveFromRooms